import React, { useState } from 'react';

import './index.scss';

import Toaster from '../../common/toaster';
import Chest from './chestBlock';

const BoxPage = () => {
  const [toast, setToast] = useState({
    message: '',
    type: 'error',
    show: false
  });

  return (
    <div className="boxMain">
      {toast.show && <Toaster toast={toast} setToast={setToast} />}
      <Chest setToast={setToast} />
    </div>
  );
};

export default BoxPage;
