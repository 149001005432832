import ApiUtils from '../apiUtils';

const signInAPI = async (data) => {
  const url = `${ApiUtils.API_BASE_URL}/auth/signin`;
  const options = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(data)
  };
  return await fetch(url, options)
    .then(async (response) => {
      const parsed = await ApiUtils.checkStatus(response);
      return parsed;
    })
    .then(async (parsed) => {
      return parsed.json();
    })
    .catch((err) => err);
};
export default signInAPI;
