export const cryptNames = {
  ada: 'Cardano',
  btc: 'Bitcoin',
  dog: 'Dogecoin',
  etc: 'Ethereum Classic',
  eth: 'Ethereum',
  lnk: 'Chainlink',
  ltc: 'Litecoin',
  mat: 'Polygon',
  sol: 'Solana',
  xrp: 'XRP'
};
