import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './index.scss';
import { getTradesThunk } from '../../store/slices/diamonds';
import buyDiamondAPI from '../../../api/diamonds/buyDiamond';
import Toaster from '../../../common/toaster';
import { getSizeOfDiamond } from '../../../helpers/getSizeOfDiamond';
const TradeCard = ({ trade }) => {
  const { token } = useSelector((state) => state.user);
  const { userData } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const [toast, setToast] = useState({
    message: '',
    type: 'error',
    show: false
  });
  const dispatch = useDispatch();

  const handleBuyButton = async () => {
    const resp = await buyDiamondAPI(token, navigate, { diamondLogId: trade.id });
    if (resp?.message && resp?.message?.includes('successfull')) {
      dispatch(getTradesThunk({ token, navigate, setToast }));
      setToast({
        message: 'Diamond bought successfully',
        type: 'success',
        show: true
      });
    } else {
      setToast({
        message: 'Sorry, something went wrong. Please try again later.',
        type: 'error',
        show: true
      });
    }
  };

  return (
    <div className="tradeCard">
      {toast.show && <Toaster toast={toast} setToast={setToast} />}

      <div
        style={{
          backgroundSize: getSizeOfDiamond(trade.type),
          backgroundImage: `url(${trade.icon})`,
          opacity: 1
        }}
        className="diamondImg"
      />
      <div className="tradeHeading">
        <h1 className="price"> $ {trade.price}</h1>

        <p className="title"> {trade.username}</p>
        <p className="description">{trade.description}</p>
      </div>
      <button
        className={`buyButton ${userData.id === trade.userId ? 'disabled' : ''}`}
        disabled={userData.id === trade.userId}
        onClick={() => {
          handleBuyButton();
        }}>
        Buy
      </button>
    </div>
  );
};

TradeCard.propTypes = {
  trade: PropTypes.object
};

export default TradeCard;
