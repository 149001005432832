import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';
import './index.scss';
import '../../index.scss';

import TopActions from '../../../../common/topActions';
import Ellipse from '../../../../common/ellipse';
import Toaster from '../../../../common/toaster';
import activateDiamondAPI from '../../../../api/diamonds/activateDiamondAPI';
import DiamondUsage from './activation';
import cancelSellingAPI from '../../../../api/diamonds/cancelSelling';
import { getDiamondsThunk, listForSaleThunk } from '../../../store/slices/diamonds';
import lockSvg from '../../../../assets/icons/lock.svg';
import { getSizeOfDiamond } from '../../../../helpers/getSizeOfDiamond';
import { DiamondHelpers } from '../../helpers';
import { diamondsConstants } from '../../constants';

const Diamond = () => {
  const location = useLocation();
  const { token } = useSelector((state) => state.user);
  const diamonds = useSelector((state) => state.diamonds.hashDiamonds);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLocked, isForExch, isForSale, filterDiamonds } = DiamondHelpers;

  const sortedDiamonds = useSelector((state) => state.diamonds.sortedDiamonds);
  const [diamondType, setDiamondType] = useState(-1);
  const [diamondData, setDiamondData] = useState({});
  const [sellPrice, setSellPrice] = useState(0.12);
  const [toast, setToast] = useState({
    message: '',
    type: 'error',
    show: false
  });
  const [activateDiamondColor, setActivateDiamondColor] = useState({});
  const [selectedDiamond, setSelectedDiamond] = useState('');
  const [applyMinimize, setApplyMinimize] = useState(false);
  const [finishedActivation, setFinishedActivation] = useState(false);

  const getParticlesColor = (idx) => {
    if (idx === 4) {
      return { r: 76, g: 130, b: 202 };
    }
    if (idx === 5) {
      return { r: 162, g: 2, b: 66 };
    }
    if (idx === 6) {
      return { r: 195, g: 138, b: 227 };
    }
    return false;
  };

  const handleActivateDiamond = async () => {
    const data = {
      diamondLogId1: diamondData.id
    };

    await activateDiamondAPI(token, navigate, data);

    let audio = new Audio('/sounds/diamondMinimize.mp3');
    audio.volume = 0.05;
    audio.play();

    setActivateDiamondColor(getParticlesColor(diamondData.type));
    setSelectedDiamond(diamondData.icon);

    setTimeout(() => {
      let explosionAudio = new Audio('/sounds/explosion1.mp3');
      explosionAudio.volume = 0.1;
      explosionAudio.play();
    }, 2200);
  };

  const isItReady = (diamondNum, idx) => {
    if (diamondNum >= 6 && idx === 0) {
      return 'ready';
    }
    if (diamondNum >= 3 && idx >= 1 && idx <= 3) {
      return 'ready';
    }
    return false;
  };

  const handleCombineDiamonds = (icon, type) => {
    navigate('/combining', { state: { type, icon } });
  };
  const setDiamondNumsStyles = () => {
    if (isLocked(diamonds, diamondData)) {
      return { width: 130 };
    }
    return { width: 60, paddingRight: 0 };
  };

  const getDiamondsNum = (isFromLockedDiamonds) => {
    const { idsForExch, idsForSave } = diamondData;
    if (idsForExch?.lockedId > 0 && idsForSave?.lockedId > 0) {
      return isFromLockedDiamonds ? 2 : diamondData.count - 2;
    }
    if (idsForExch?.lockedId > 0 || idsForSave?.lockedId > 0) {
      return isFromLockedDiamonds ? 1 : diamondData.count - 1;
    }
    return diamondData.count;
  };

  const checkActiveBtn = (fromButton) => {
    const diamondCount = getDiamondsNum();
    if (
      (diamondData.type === 4 || diamondData.type === 5 || diamondData.type === 6) &&
      diamondCount > 0
    ) {
      if (fromButton) {
        return true;
      }
      return handleActivateDiamond(diamondData, diamondData.type);
    }
    if (isItReady(diamondCount, diamondData.type)) {
      if (fromButton) {
        return true;
      }
      return handleCombineDiamonds(diamondData.icon, diamondData.type);
    } else {
      if (fromButton) {
        return false;
      }
      return () => {};
    }
  };

  const handleCancelSellButton = async () => {
    await cancelSellingAPI(token, navigate, { diamondLogId: diamondData.idsForSave.lockedId });
    dispatch(getDiamondsThunk({ token, navigate }));
  };

  const handleSellButton = async () => {
    try {
      const payload = { diamondLogId: diamondData.idsForSave.freeId, price: sellPrice };
      const resp = await dispatch(listForSaleThunk({ token, navigate, payload }));
      const { message } = resp.payload;
      if (message && message?.includes('successful')) {
        setToast({
          message: 'Diamond listed for sale',
          type: 'success',
          show: true
        });
        dispatch(getDiamondsThunk({ token, navigate }));
      } else {
        setToast({
          message: 'Sorry, something went wrong. Please try again later.',
          type: 'error',
          show: true
        });
      }
    } catch (error) {
      setToast({
        message: 'Sorry, something went wrong. Please try again later.',
        type: 'error',
        show: true
      });
    }
  };
  const handleBuyButton = () => {
    if (diamondData.type === 5) {
      window.location.href = 'https://buy.stripe.com/3cs8wWbb39HK6Nq9AM';
      return;
    }
    navigate('/diamonds/trades');
  };

  const handleExchangeButton = () => {
    if (isForExch(diamonds, diamondData)) {
      navigate('/diamonds/exchanges');
      return;
    }
    navigate('/diamonds/exchange', { state: { diamond: diamondData } });
  };

  useEffect(() => {
    if (token && location.state) {
      setDiamondType(location.state.diamond.type);
      dispatch(getDiamondsThunk({ token, navigate }));
    }
  }, [token, location.state]);

  useEffect(() => {
    if (Object.keys(diamonds).length) {
      filterDiamonds(diamonds, dispatch);
    }
  }, [diamonds]);

  useEffect(() => {
    if (Object.keys(sortedDiamonds).length) {
      setDiamondData({ ...sortedDiamonds[diamondType], ...diamondsConstants[diamondType] });
    }
  }, [sortedDiamonds]);

  return (
    <div>
      {activateDiamondColor?.r && (
        <>
          <DiamondUsage
            particlesColor={activateDiamondColor}
            setApplyMinimize={setApplyMinimize}
            applyMinimize={applyMinimize}
            finishedActivation={finishedActivation}
            setFinishedActivation={setFinishedActivation}
          />
          <img
            className={`diamondImage ${applyMinimize ? 'minimize' : ''}`}
            src={selectedDiamond}></img>
          {finishedActivation && (
            <>
              <p className="diamondText">
                Visit the strategy screen to view your currently active bonuses.
              </p>
              <button className="diamondClose" onClick={() => navigate('/strategy')} type="button">
                Go to strategy
              </button>
            </>
          )}
        </>
      )}
      <div className="diamondMain">
        {!diamondData.icon ? (
          <div style={{ display: 'flex', alignItems: 'center', height: '100dvh' }}>
            <Spin />
          </div>
        ) : (
          <div className="diamondContainer">
            <TopActions left center={'Diamond'} navigateBack />
            <div className="diamondPreview">
              {toast.show && <Toaster toast={toast} setToast={setToast} />}

              <Ellipse />
              <div
                style={{
                  backgroundSize: getSizeOfDiamond(diamondData.type),
                  backgroundImage: `url(${diamondData.icon})`,
                  opacity: 1
                }}
                className="diamond"></div>
              <div className="diamondShine rotate"></div>
              <div className="diamondShadow reverse"></div>
            </div>
            <div className="diamondStats">
              <div className="diamondHeading">
                <div className="diamondNumber" style={setDiamondNumsStyles()}>
                  <p
                    className="count"
                    style={isLocked(diamonds, diamondData) ? {} : { paddingRight: 0 }}>
                    {getDiamondsNum()}
                  </p>
                  {isLocked(diamonds, diamondData) ? (
                    <div
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <img className="locked" src={lockSvg} />
                      <p className="lockedNum">{getDiamondsNum(true)}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <h1 className="title">{diamondData.title}</h1>
                <p className="description">{diamondData.description}</p>
              </div>
              <footer>
                <div className="tradeBlock">
                  {isForSale(diamonds, diamondData) ? (
                    <button
                      style={{ width: 170 }}
                      className={'buy '}
                      // disabled={!getDiamondsNum()}
                      onClick={handleCancelSellButton}>
                      Cancel Sell
                    </button>
                  ) : (
                    <div className="btnsBlock">
                      <div className="sellBtnBlock">
                        <p className={`dollarSign ${getDiamondsNum() ? '' : 'inactiveDollar'}`}>
                          $
                        </p>
                        <input
                          value={sellPrice}
                          onChange={(e) => {
                            setSellPrice(e.target.value);
                          }}
                          disabled={!getDiamondsNum()}
                          className={` ${getDiamondsNum() ? '' : 'inactiveSellInput'}`}
                          type="number"
                        />
                        <button
                          className={`sell ${getDiamondsNum() ? '' : 'inactiveSell'}`}
                          disabled={!getDiamondsNum()}
                          onClick={handleSellButton}>
                          Sell
                        </button>
                      </div>
                    </div>
                  )}
                  <button className="buy" onClick={handleBuyButton}>
                    Buy
                  </button>
                </div>
                <button
                  className={`activateButton ${checkActiveBtn(true) ? '' : 'inactiveBtn'}`}
                  onClick={() => {
                    checkActiveBtn();
                  }}>
                  Activate
                </button>

                <button
                  disabled={!getDiamondsNum()}
                  className={`exchangeButton ${getDiamondsNum() < 1 ? 'inactiveBtn' : ''}`}
                  onClick={() => {
                    handleExchangeButton();
                  }}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className={` ${getDiamondsNum() < 1 ? 'inactiveExchange' : ''}`}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g id="Item/tabler-icon-transform">
                      <path
                        id="Vector"
                        d="M21 11V8C21 7.46957 20.7893 6.96086 20.4142 6.58579C20.0391 6.21071 19.5304 6 19 6H13M13 6L16 9M13 6L16 3M3 13V16C3 16.5304 3.21071 17.0391 3.58579 17.4142C3.96086 17.7893 4.46957 18 5 18H11M11 18L8 15M11 18L8 21M3 6C3 6.79565 3.31607 7.55871 3.87868 8.12132C4.44129 8.68393 5.20435 9 6 9C6.79565 9 7.55871 8.68393 8.12132 8.12132C8.68393 7.55871 9 6.79565 9 6C9 5.20435 8.68393 4.44129 8.12132 3.87868C7.55871 3.31607 6.79565 3 6 3C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6ZM15 18C15 18.7956 15.3161 19.5587 15.8787 20.1213C16.4413 20.6839 17.2044 21 18 21C18.7956 21 19.5587 20.6839 20.1213 20.1213C20.6839 19.5587 21 18.7956 21 18C21 17.2044 20.6839 16.4413 20.1213 15.8787C19.5587 15.3161 18.7956 15 18 15C17.2044 15 16.4413 15.3161 15.8787 15.8787C15.3161 16.4413 15 17.2044 15 18Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                  <p style={{ marginLeft: 5 }}>
                    {' '}
                    {isForExch(diamonds, diamondData) ? 'Go To Exchange' : 'Exchange'}
                  </p>
                </button>
              </footer>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
Diamond.propTypes = {
  handleClickButton: PropTypes.object
};
export default Diamond;
