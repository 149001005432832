import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import './index.scss';

const DiamondCard = ({ diamond, filteredDiamonds, idx }) => {
  const navigate = useNavigate();
  const getSizeOfDiamond = (idx) => {
    if (idx === 1) {
      return '80%';
    } else if (idx > 1 && idx < 4) {
      return '75%';
    } else if (idx > 3 && idx < 8) {
      return '70%';
    }

    return '105%';
  };

  const getGradientColors = () => {
    const type = diamond.type;
    switch (type) {
      case 1:
        return 'green-diamond-gradient';
      case 2:
        return 'red-diamond-gradient';
      case 3:
        return 'blue-diamond-gradient';
      case 4:
        return 'shine-diamond-gradient';
      case 5:
        return 'fiery-diamond-gradient';
      case 6:
        return 'tide-diamond-gradient';
      case 7:
        return 'gold-diamond-gradient';
    }
    return 'radial-gradient(circle at center, rgba(0, 0, 0, 1) 0%, rgba(0, 255, 0, 1) 50%, rgba(0, 255, 0, 1) 100%)';
  };

  const isItReady = (diamondNum, idx) => {
    if (diamondNum >= 6 && idx === 0) {
      return 'ready';
    }
    if (diamondNum >= 3 && idx >= 1 && idx <= 3) {
      return 'ready';
    }
    if (diamondNum >= 1 && idx >= 4 && idx <= 6) {
      return 'ready';
    }
    return false;
  };

  return (
    <div key={diamond.type} style={{ maxWidth: 420, minWidth: 320 }}>
      <div
        onClick={() => {
          if (diamond.type !== 7) {
            navigate(`/diamonds/${diamond.type}`, {
              state: { diamond: { ...diamond, ...filteredDiamonds[idx] } }
            });
          }
        }}
        className={`diamondsBlock ${
          filteredDiamonds[idx].count ? 'active' : 'inactive'
        } ${isItReady(filteredDiamonds[idx].count, idx)}`}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            justifyItems: 'center'
          }}>
          <div
            style={{
              backgroundSize: getSizeOfDiamond(idx),
              backgroundImage: `url(${diamond.icon})`,
              opacity: 1
            }}
            className="diamond"
          />
          {filteredDiamonds[idx].count ? (
            <div className={`diamondShadow ${getGradientColors()}`} />
          ) : (
            <></>
          )}
        </div>
        <div className="diamondStats">
          <div className="diamondHeading">
            <h1>
              {diamond.title}: {filteredDiamonds[idx].count}{' '}
            </h1>
            <p>{diamond.description} </p>
          </div>
        </div>
      </div>
    </div>
  );
};
DiamondCard.propTypes = {
  diamond: PropTypes.object,
  filteredDiamonds: PropTypes.object,
  idx: PropTypes.number
};
export default DiamondCard;
