import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { parseDiamonds } from '../../../helpers/parseDiamonds';
import claimChestAPI from '../../../api/diamonds/claimChest';
import { descriptions } from '../constant';
import ShiningStar from '../shine';
import '../index.scss';
import GradientButton from '../../../common/gradientButton';

const Chest = ({ setToast, fromCombine, setChestOpened, combineBox }) => {
  const { box } = useSelector((state) => state.notifications);
  const { token } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const [startOpening, setStartOpening] = useState(false);

  const [flashVisible, setFlashVisible] = useState(false);
  const [descriptionText, setDescriptionText] = useState(fromCombine ? '' : descriptions.header);
  const [boxImg, setBoxImg] = useState('/boxes/box.png');
  const [enableFlashAnimation, setEnableFlashAnimation] = useState(true);

  const showButton = () => {
    if (!enableFlashAnimation && !fromCombine) {
      return true;
    }
    return false;
  };

  const getDiamondType = (hashDiamonds) => {
    if (hashDiamonds[combineBox || box]?.type === 0) {
      setDescriptionText(descriptions.gray);
      setBoxImg('/boxes/grayBox.png');
    } else if (hashDiamonds[combineBox || box]?.type === 1) {
      setDescriptionText(descriptions.green);
      setBoxImg('/boxes/greenBox.png');
    } else if (hashDiamonds[combineBox || box]?.type === 2) {
      setDescriptionText(descriptions.red);
      setBoxImg('/boxes/redBox.png');
    } else if (hashDiamonds[combineBox || box]?.type === 3) {
      setDescriptionText(descriptions.blue);
      setBoxImg('/boxes/blueBox.png');
    }
  };

  const handleStartOpening = async () => {
    setStartOpening(true);

    let hashDiamonds = {};
    try {
      await claimChestAPI(token, navigate, combineBox || box);
    } catch (err) {
      setToast({ message: err, type: 'error', show: true });
    }
    try {
      hashDiamonds = await parseDiamonds(token, navigate);
    } catch (err) {
      setToast({ message: err, type: 'error', show: true });
    }

    getDiamondType(hashDiamonds);
    setStartOpening(false);
    handleChestOpen();
  };

  const handleChestOpen = () => {
    setEnableFlashAnimation(false);

    setFlashVisible(true);
    let audio = new Audio('/sounds/magicChest.mp3');
    audio.volume = 0.2;

    audio.play();

    setTimeout(() => {
      setFlashVisible(false);
      if (setChestOpened) {
        setChestOpened(true);
      }
    }, 1100);
  };

  return (
    <div className="boxBody" style={fromCombine ? { width: '40%', height: '50%' } : {}}>
      <div className="boxContainer" style={fromCombine ? { width: '100%', height: '100%' } : {}}>
        <div
          style={
            fromCombine
              ? { width: '100%', height: '100%', backgroundImage: `url(${boxImg})` }
              : { backgroundImage: `url(${boxImg})` }
          }
          className={`${startOpening ? 'shakingChest' : ''} box`}
          onClick={
            enableFlashAnimation
              ? (e) => {
                  e.stopPropagation();
                  handleStartOpening();
                }
              : () => {}
          }
        />
        {!fromCombine ? <div className="dropShadow" /> : <></>}

        <ShiningStar flashVisible={flashVisible} fromCombine={fromCombine} />
      </div>
      {!fromCombine ? (
        <p style={{ width: '100%' }} className="descriptionText">
          {descriptionText}
        </p>
      ) : (
        <></>
      )}
      {showButton() && (
        <GradientButton
          handleClickButton={() => {
            navigate('/');
          }}
        />
      )}
    </div>
  );
};

Chest.propTypes = {
  setToast: PropTypes.func.isRequired,
  fromCombine: PropTypes.bool,
  setChestOpened: PropTypes.func,
  combineBox: PropTypes.number
};

export default Chest;
