import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CloseCircleOutlined } from '@ant-design/icons';

import './index.scss';

const Toaster = (props) => {
  const { toast, setToast } = props;
  const removeToast = () => {
    setToast({ message: '', type: 'error', show: false });
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setToast({ message: '', type: 'error', show: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [toast, setToast]);

  return (
    <div className="toast-container">
      <div className={`toast ${toast.type}`}>
        <div className="toast-content">
          <p className="toast-message">{toast.message}</p>
          <button className="toast-dismiss-button" onClick={() => removeToast()}>
            <CloseCircleOutlined />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Toaster;

Toaster.propTypes = {
  toast: PropTypes.object,
  setToast: PropTypes.func
};
