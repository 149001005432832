import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Toaster from '../../common/toaster';
import readNotificationsAPI from '../../api/user/readNotifications';
import { useNavigate, useLocation } from 'react-router-dom';
import { getNotificationsThunk } from '../store/slices/notifications';

const NotificationCard = ({ date, currentDateNotifications }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);

  const [toast, setToast] = useState({
    message: '',
    type: 'error',
    show: false
  });
  const handleReadNotification = async (isUserMessage, id) => {
    if (isUserMessage) {
      await readNotificationsAPI(token, navigate, { body: { messageIds: [id], markAll: false } });
      dispatch(
        getNotificationsThunk({
          token,
          navigate,
          pathname: location.pathname,
          setToast
        })
      );
    }
  };
  return (
    <div className="notificationBlock">
      {toast.show && <Toaster toast={toast} setToast={setToast} />}

      <h3 className="notificationDate">{date}</h3>
      {currentDateNotifications.map((notification) => {
        return (
          <div key={notification.id} className="notificationDescBlock">
            <p className="notificationDesc">{notification.desc}</p>
            {!notification.read && notification.isUserMessage && (
              <div
                onClick={() => {
                  handleReadNotification(notification.isUserMessage, notification.id);
                }}
                className="unreadNotifications"
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
export default NotificationCard;

NotificationCard.propTypes = {
  date: PropTypes.string,
  currentDateNotifications: PropTypes.array
};
