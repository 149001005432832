import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';

import './index.scss';

const DiamondUsage = (props) => {
  const refContainer = useRef(null);
  const {
    particlesColor,
    setApplyMinimize,
    applyMinimize,
    finishedActivation,
    setFinishedActivation
  } = props;

  useEffect(() => {
    let scene = new THREE.Scene();

    // Camera
    let camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 5;

    // Renderer
    let renderer = new THREE.WebGLRenderer({
      alpha: true // Enable alpha channel
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    refContainer.current && refContainer.current.appendChild(renderer.domElement);

    // Diamond mesh
    // const diamond = new THREE.Mesh(geometry, material);
    const diamondCenter = new THREE.Group();
    scene.add(diamondCenter);
    // scene.add(diamond);

    // Ambient light
    const ambientLight = new THREE.AmbientLight(0x404040);
    scene.add(ambientLight);

    // Directional light
    const directionalLight = new THREE.DirectionalLight(0xffffff, 2);
    directionalLight.position.set(1, 1, 1).normalize();
    scene.add(directionalLight);

    // Particle system
    const particleGeometry = new THREE.BufferGeometry();
    const particleMaterial = new THREE.PointsMaterial({
      color: 0xffffff,
      size: 0.02,
      transparent: true,
      opacity: 0,
      vertexColors: true,
      sizeAttenuation: true
    });

    // Create particles around the diamond with random colors
    const particles = [];
    const colors = [];
    // const baseColor = { r: props.color.r, g: props.color.g, b: props.color.b }; // Base color
    // const colorVariation = 20; // Adjust the color variation as needed

    for (let i = 0; i < 2000; i++) {
      const phi = Math.random() * Math.PI * 2;
      const theta = Math.random() * Math.PI * 2;
      const radius = Math.random() * 1.5; // Adjust the radius as needed

      const x = radius * Math.sin(theta) * Math.cos(phi);
      const y = radius * Math.sin(theta) * Math.sin(phi);
      const z = radius * Math.cos(theta);

      particles.push(x, y, z);

      colors.push(particlesColor.r, particlesColor.g, particlesColor.b);
      //   colors.push(props.color.r, props.color.g, props.color.b);
    }

    particleGeometry.setAttribute('position', new THREE.Float32BufferAttribute(particles, 3));
    particleGeometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
    const particleSystem = new THREE.Points(particleGeometry, particleMaterial);
    diamondCenter.add(particleSystem);

    // Bloom pass
    const renderScene = new RenderPass(scene, camera);
    const bloomPass = new UnrealBloomPass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      1.5,
      0.4,
      0.85
    );
    bloomPass.threshold = 0.95;
    bloomPass.strength = 0.25;
    bloomPass.radius = 0.14;

    // Effect Composer
    const composer = new EffectComposer(renderer);
    composer.addPass(renderScene);
    composer.addPass(bloomPass);

    // Animation
    let convergenceFactor = 0.9; // Adjust the convergence factor as needed
    let explosionStarted = false;
    const animate = function () {
      requestAnimationFrame(animate);

      // Rotate the diamond
      diamondCenter.rotation.x += 0.01;
      diamondCenter.rotation.y += 0.01;

      // Increase opacity gradually until particles converge
      if (particleMaterial.opacity < 1 && !explosionStarted) {
        particleMaterial.opacity += 0.011;
      } else {
        if (!applyMinimize) {
          setApplyMinimize(true);
        }
        explosionStarted = true;

        // Gradually bring particles closer to the diamond
        if (convergenceFactor > 0.001) {
          particleGeometry.attributes.position.array.forEach((coord, index) => {
            particleGeometry.attributes.position.array[index] *= convergenceFactor;
          });
          particleGeometry.attributes.position.needsUpdate = true;

          // Increase opacity for the explosion
          particleMaterial.opacity += 0.004;
        }

        // Stop the animation when particles are dispersed
        if (particleMaterial.opacity >= 1.2) {
          convergenceFactor = 1.15;
        }
        if (particleMaterial.opacity >= 1.7) {
          if (!finishedActivation) {
            setFinishedActivation(true);
          }
          explosionStarted = true;
          return;
        }
      }

      // Render scene with bloom effect
      composer.render();
    };

    animate();
    // };
  }, []);

  return <div ref={refContainer} className="diamondUsage" />;
};

DiamondUsage.propTypes = {
  particlesColor: PropTypes.object,
  setApplyMinimize: PropTypes.func,
  applyMinimize: PropTypes.bool,
  setFinishedActivation: PropTypes.func,
  finishedActivation: PropTypes.bool
};

export default DiamondUsage;
