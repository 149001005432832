import ApiUtils from '../apiUtils';

const signUpAPI = async (data) => {
  const url = `${ApiUtils.API_BASE_URL}/auth/signup`;
  const options = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(data)
  };
  return await fetch(url, options)
    .then(async (response) => {
      const parsed = await ApiUtils.checkStatus(response);
      return parsed;
    })
    .then((parsed) => parsed.json())
    .catch((err) => err);
};
export default signUpAPI;
