import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getNotificationsAPI from '../../../api/user/getNotifications';
import {
  getBoxes,
  getStatusNotis,
  getUserNotifications
  // parseUserNotifications
} from '../../../helpers/parseUserNotifications';

export const initialState = {
  token: '',
  notifications: [],
  box: 0,
  isUnreadMessages: false,
  loading: false
};

export const getNotificationsThunk = createAsyncThunk(
  'notification',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getNotificationsAPI(data.token, data.navigate);
      if (response.success === false) {
        return rejectWithValue(response.message || 'Error');
      }
      return {
        response,
        setToast: data.setToast,
        pathname: data.pathname,
        navigate: data.navigate
      };
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.notifications = action.payload.info;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotificationsThunk.fulfilled, (state, action) => {
        try {
          let isThereCustomNotification = { exist: false, id: 0 };
          const parsedNotificationData = JSON.parse(action.payload.response.message);
          const optionsDate = {
            day: '2-digit',
            month: 'long'
          };

          if (parsedNotificationData.data) {
            const { userMessages, statusArray, box } = parsedNotificationData.data;

            const parsedNotifications = [];
            const notificationsByDate = {};

            const isThereUnreadMessage =
              !!statusArray.length || userMessages.some((message) => !message.read);
            state.isUnreadMessages = isThereUnreadMessage;
            getStatusNotis(statusArray, parsedNotifications);
            getUserNotifications(
              userMessages,
              isThereCustomNotification,
              optionsDate,
              notificationsByDate
            );
            state.box = getBoxes(box);

            const sortedNotifications = Object.entries(notificationsByDate)
              .sort(([a], [b]) => {
                if (a === 'Today') {
                  return -1;
                } else if (b === 'Today') {
                  return 1;
                } else {
                  return new Date(b) - new Date(a);
                }
              })
              .map(([date, notifications]) => ({ date, notifications }));

            state.notifications = [{ notifications: parsedNotifications }, ...sortedNotifications];
          }
          if (
            isThereCustomNotification.exist &&
            action.payload.pathname !== '/customNotification'
          ) {
            action.payload.navigate('customNotification', {
              state: {
                id: isThereCustomNotification.id,
                message: isThereCustomNotification.message
              }
            });
          }
        } catch (e) {
          action.payload.setToast({
            message: 'Sorry, something went wrong. Please try again later.',
            type: 'error',
            show: true
          });
        }
        state.loading = false;
        // state.userData = JSON.parse(action.payload.notifications.message);
      })
      .addCase(getNotificationsThunk.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const { updateNotifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;
