// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/ellipse.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ellipseContainer {
  display: flex;
  justify-content: center;
  top: 50px;
  width: 100%;
  height: 90%;
  position: absolute;
}
.ellipseContainer .outer {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  position: absolute;
  opacity: 0.1;
  min-width: 90%;
  width: 100%;
  height: 100%;
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-position: center;
}
.ellipseContainer .middle {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  position: absolute;
  opacity: 0.4;
  min-width: 90%;
  width: 68%;
  height: 68%;
  display: flex;
  align-self: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-position: center;
}
.ellipseContainer .inner {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  position: absolute;
  opacity: 0.9;
  min-width: 90%;
  width: 50%;
  height: 50%;
  display: flex;
  align-self: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-position: center;
}`, "",{"version":3,"sources":["webpack://./src/common/ellipse/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;EACA,kBAAA;AACJ;AAAI;EACI,yDAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,4BAAA;EACA,2BAAA;EACA,wBAAA;EACA,2BAAA;AAER;AACI;EACI,yDAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;EACA,UAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;EACA,4BAAA;EACA,2BAAA;EACA,wBAAA;EACA,2BAAA;AACR;AAGI;EACI,yDAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;EACA,UAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;EACA,4BAAA;EACA,2BAAA;EACA,wBAAA;EACA,2BAAA;AADR","sourcesContent":[".ellipseContainer{\n    display: flex;\n    justify-content: center;\n    top: 50px;\n    width: 100%;\n    height: 90%;\n    position: absolute;\n    .outer{\n        background-image: url('../../assets/img/ellipse.svg');\n        position: absolute;\n        opacity: 0.1;\n        min-width: 90%;\n        width: 100%;\n        height: 100%;\n        background-size: 90%;\n        background-repeat: no-repeat;\n        background-position: center;\n        background-size: contain;\n        background-position: center;\n\n    }\n    .middle{\n        background-image: url('../../assets/img/ellipse.svg');\n        position: absolute;\n        opacity: 0.4;\n        min-width: 90%;\n        width: 68%;\n        height: 68%;\n        display: flex;\n        align-self: center;\n        background-repeat: no-repeat;\n        background-position: center;\n        background-size: contain;\n        background-position: center;\n\n        \n    }\n    .inner{\n        background-image: url('../../assets/img/ellipse.svg');\n        position: absolute;\n        opacity: 0.9;\n        min-width: 90%;\n        width: 50%;\n        height: 50%;\n        display: flex;\n        align-self: center;\n        background-repeat: no-repeat;\n        background-position: center;\n        background-size: contain;\n        background-position: center;\n        \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
