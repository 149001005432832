const checkSettingsWarns = (userData) => {
  const warns = [];
  if (!userData?.tg) {
    warns.push('Telegram username not set in Settings');
  }
  if (!userData?.trc) {
    warns.push('Crypto wallet not set in Settings');
  }
  if (!userData?.hisskey) {
    warns.push('Hiss key not set in Settings');
  }
  return warns;
};
export default checkSettingsWarns;
