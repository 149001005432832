import ApiUtils from '../apiUtils';

const getPlanAPI = async (token, navigate, setLoading) => {
  const url = `${ApiUtils.API_BASE_URL}/user/plan`;
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };
  if (setLoading) {
    setLoading(true);
  }
  return await fetch(url, options)
    .then(async (response) => {
      const parsed = await ApiUtils.checkStatus(response, navigate);
      return parsed;
    })
    .then((parsed) => {
      if (setLoading) {
        setLoading(false);
      }

      return parsed.json();
    })
    .catch((err) => err);
};
export default getPlanAPI;
