import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const ShiningStar = ({ flashVisible, fromCombine }) => {
  return (
    <div className="shineBlock">
      {fromCombine ? (
        <></>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 439.16 448.24" width="300px">
          <defs></defs>
          <title>shine</title>
          <g id="OBJECTS">
            <polygon
              className="cls-1 shine star-2"
              points="224.25 207.06 263.15 205.7 224.62 204.36 243.03 193.17 222.85 202.47 233.02 182.72 220.83 200.93 219.47 162.02 218.13 200.56 206.94 182.15 216.24 202.32 196.49 192.16 214.7 204.34 175.79 205.7 214.33 207.05 195.92 218.24 216.09 208.94 205.93 228.69 218.12 210.47 219.47 249.38 220.82 210.85 232.01 229.25 222.71 209.08 242.46 219.25 224.25 207.06"
            />
          </g>
        </svg>
      )}
      <div className={`  ${flashVisible ? 'openChest' : ''}`} />
    </div>
  );
};

export default ShiningStar;

ShiningStar.propTypes = {
  flashVisible: PropTypes.bool.isRequired,
  fromCombine: PropTypes.bool
};
