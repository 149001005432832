import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Col, Row } from 'antd';
import { AppContext } from '../appContext';
const PlanCard = ({ handleSelectButton, plan, selectedPlan, userPlans }) => {
  const { planWarns } = useContext(AppContext);
  const userData = useSelector((state) => state.user?.userData);

  const [customMessage, setCustomMessage] = useState('');

  const getPlanIcon = () => {
    if (plan.name === "Rookie's Path") {
      return '/plans/rookieIcon.png';
    } else if (plan.name === 'Golden Key') {
      return '/plans/goldenIcon.png';
    } else if (plan.name === 'Royal League') {
      return '/plans/royalIcon.png';
    } else if (plan.name === "Dragon's Order") {
      return '/plans/dragonsIcon.png';
    } else if (plan.name === 'Crystal Covenant') {
      return '/plans/crystalIcon.png';
    } else if (plan.name === 'Mystic Monarchs') {
      return '/plans/mysticIcon.png';
    }
  };

  const getButtonText = () => {
    if (userPlans === plan.id) {
      return '✓ Active';
    }
    return 'Buy Now';
  };

  const handleBuyPlan = (link) => {
    window.open(link, '_blank');
  };

  useEffect(() => {
    if (!planWarns.length) {
      setCustomMessage('');
    } else if (userData.plans === plan.id) {
      setCustomMessage(planWarns[0]);
    }
  }, [planWarns]);
  return (
    <div
      onClick={() => {
        handleSelectButton(plan.name);
      }}
      className={`planElement ${plan.name === selectedPlan && 'selectedPlanElement'} ${
        plan.id === userPlans && 'activePlanElement'
      }`}>
      <Col span={24}>
        <Row>
          <div className="planInfo">
            <img className="planIcon" width={174} height={174} src={getPlanIcon()} />
            <span className="planTitle">{plan.name}</span>
            {customMessage ? (
              <Col>
                <p className="planPrice planWarn">{customMessage}</p>
              </Col>
            ) : (
              <Col>
                <p className="planPrice">Price: {plan.price}</p>
                <p className="planProfit">Earning up to: {plan.profit_limit}</p>
                <p className="planTrade">Deal amount: {plan.trade_limit}</p>
              </Col>
            )}
          </div>
        </Row>
        <button
          disabled={userPlans === plan.id}
          onClick={() => handleBuyPlan(plan.link)}
          className="buyNowButton">
          {getButtonText()}
        </button>
      </Col>
    </div>
  );
};
export default PlanCard;

PlanCard.propTypes = {
  handleSelectButton: PropTypes.func,
  plan: PropTypes.object,
  selectedPlan: PropTypes.string,
  userPlans: PropTypes.number
};
