import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './index.scss';
import Chest from '../box/chestBlock';
import Toaster from '../../common/toaster';
import GradientButton from '../../common/gradientButton';
import createChestAPI from '../../api/diamonds/createChest';
import createDiamondAPI from '../../api/diamonds/createDiamond';
import { getSizeOfDiamond } from '../../helpers/getSizeOfDiamond';

const Combining = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const diamonds = useSelector((state) => state.diamonds.hashDiamonds);
  const { token } = useSelector((state) => state.user);

  const [combineArray, setCombineArray] = useState([]);
  const [diamondIcon, setDiamondIcon] = useState('');
  const [isClicked, setIsClicked] = useState(false);
  const [flashVisible, setFlashVisible] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(false);
  const [chestOpened, setChestOpened] = useState(false);
  const [combinedText, setCombinedText] = useState('Tap on the circle to start merging crystals');
  const [toast, setToast] = useState({
    message: '',
    type: 'error',
    show: false
  });
  const [combineBox, setCombineBox] = useState(0);

  const fulfillCombine = () => {
    if (location.state?.type === 0) {
      return Array.from({ length: getNumOfDiamonds() / 2 }, (_, i) => i + 1);
    }
    return Array.from({ length: getNumOfDiamonds() / 2 }, (_, i) => i + 1);
  };

  const getResultDiamond = () => {
    if (location.state?.type === 1) {
      return '/diamonds/shiningVictory.png';
    }
    if (location.state?.type === 2) {
      return '/diamonds/fierySuccess.png';
    }
    if (location.state?.type === 3) {
      return '/diamonds/tideOfSuccess.png';
    }
    return <></>;
  };
  const getGradientColors = () => {
    const type = location.state?.type;
    if (type === 1) {
      return 'green-gradient';
    } else if (type === 2) {
      return 'red-gradient';
    } else if (type === 3) {
      return 'blue-gradient';
    }
    return 'radial-gradient(circle at center, rgba(0, 0, 0, 1) 0%, rgba(0, 255, 0, 1) 50%, rgba(0, 255, 0, 1) 100%)';
  };

  const renderResult = () => {
    if (location.state?.type === 0) {
      return (
        <Chest
          setToast={setToast}
          fromCombine
          setChestOpened={setChestOpened}
          combineBox={combineBox}
        />
      );
    }
    return <img width={'50%'} height={'50%'} src={getResultDiamond()} />;
  };

  const getNumOfDiamonds = (fromItemNums) => {
    if (location.state?.type === 0) {
      return fromItemNums ? 2 : 12;
    }

    return fromItemNums ? 4 : 6;
  };

  const handleCircleClick = async () => {
    setIsClicked(!isClicked);
    let audio = new Audio('/sounds/windCrystals.mp3');
    audio.volume = 0.4;
    audio.play();
    const data = {};
    let catches = 0;
    for (const diamond in diamonds) {
      if (diamonds[diamond].type === location.state?.type) {
        if (catches < getNumOfDiamonds() / 2) {
          catches++;
          const dynamicPropertyName = `diamondLogId${catches}`;
          data[dynamicPropertyName] = diamonds[diamond].id;
        }
      }
    }
    let resp = '';
    if (location.state?.type === 0) {
      resp = await createChestAPI(token, navigate);
    } else {
      resp = await createDiamondAPI(token, navigate, data);
      setChestOpened(true);
    }
    try {
      setCombineBox(JSON.parse(resp.message).data.box);
    } catch (e) {
      //   console.log('somethin went worng', e);
    }
    setTimeout(() => {
      setFlashVisible(true);
      setAnimationFinished(true);
      if (location.state?.type === 0) {
        setCombinedText('Open the chest that you found!');
      } else {
        setCombinedText('You found new crystals!');
      }
    }, 900);
    setTimeout(() => {
      setFlashVisible(false);
    }, 2000);
  };

  useEffect(() => {
    if (location.state?.type + 1 ?? location.state?.icon) {
      setCombineArray(fulfillCombine());
      setDiamondIcon(location.state?.icon);
    }
  }, [location.state?.type]);

  return (
    <div className={`combineMain ${isClicked ? 'clicked' : ''}`}>
      {toast.show && <Toaster toast={toast} setToast={setToast} />}
      <p className="instructionCircle">{combinedText}</p>
      <div className="combineContainer">
        <div
          className={`circle ${getGradientColors()}`}
          style={{
            '--total': getNumOfDiamonds()
          }}
          onClick={handleCircleClick}>
          {animationFinished
            ? renderResult()
            : combineArray.map((_, idx) => (
                <div
                  key={idx + 1}
                  className="stat diamond"
                  style={{
                    backgroundSize: getSizeOfDiamond(idx),
                    backgroundImage: `url(${diamondIcon})`,
                    '--i': idx * getNumOfDiamonds(true)
                  }}
                />
              ))}
          <div
            style={!flashVisible ? { display: ' none' } : { display: 'block' }}
            className={`${getGradientColors()}  ${flashVisible ? 'diamondsReaction' : ''}`}
          />
        </div>
        {chestOpened && (
          <GradientButton
            handleClickButton={() => {
              navigate('/');
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Combining;
