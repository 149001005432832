import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

import './index.scss';
import TopActions from '../../common/topActions';
import getPlanAPI from '../../api/user/getPlan';
// import { AppContext } from '../appContext';
import PlanCard from './planCard';
import Toaster from '../../common/toaster';

const Plans = () => {
  const { token, userData } = useSelector((state) => {
    return state.user;
  });
  let settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: userData.plans,
    speed: 200,
    arrows: false,
    adaptiveHeight: true,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: () => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    )
  };
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState("Rookie's Path");
  const [toast, setToast] = useState({
    message: '',
    type: 'error',
    show: false
  });

  const navigate = useNavigate();
  const parsePlans = async () => {
    const resp = await getPlanAPI(token, navigate, setLoading);
    if (resp?.message && resp?.message.indexOf('Failed') === -1) {
      const modifiedPlans = JSON.parse(resp.message).plans;
      modifiedPlans[1].link = 'https://buy.stripe.com/aEU7sS7YRaLOb3G5kr';
      modifiedPlans[2].link = 'https://buy.stripe.com/8wM14ugvn8DGb3GbIQ';
      modifiedPlans[3].link = 'https://buy.stripe.com/6oE9B00wp8DG5Jm3cl';
      modifiedPlans[4].link = 'https://buy.stripe.com/3cs5kK2Ex7zCc7K6oy';
      modifiedPlans[5].link = 'https://buy.stripe.com/dR600qbb33jmdbO7sD';

      setPlans(modifiedPlans);
    }
    setLoading(false);
  };

  const handleSelectButton = (plan) => {
    setSelectedPlan(plan);
  };
  const getUserPlan = () => {
    const userPlan = userData.plans;
    return plans.find((plan) => plan.id === userPlan)?.name;
  };

  useEffect(() => {
    if (token) {
      setLoading(true);
      parsePlans();
    }
  }, [token]);

  useEffect(() => {
    if (userData && plans) {
      setSelectedPlan(getUserPlan());
    }
  }, [userData, plans]);

  return (
    <div className="plansMain">
      {toast.show && <Toaster toast={toast} setToast={setToast} />}
      <div className="plansContainer">
        <TopActions marginLeft={20} left center="Plans" />

        <div
          style={loading ? { display: 'flex', justifyContent: 'center' } : {}}
          className="plansSlider">
          {loading ? (
            <Spin />
          ) : (
            <Slider {...settings}>
              {plans.map((plan) => {
                return (
                  <PlanCard
                    key={plan.name}
                    plan={plan}
                    handleSelectButton={handleSelectButton}
                    selectedPlan={selectedPlan}
                    userPlans={userData?.plans}
                  />
                );
              })}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
};
export default Plans;
