import getPlanAPI from '../api/user/getPlan';

const checkPlanWarns = async (userData, token, navigate, setPlanWarns) => {
  const warns = [];
  let plans = [];
  const resp = await getPlanAPI(token, navigate);
  if (resp?.message && resp?.message.indexOf('Failed') === -1) {
    plans = JSON.parse(resp.message).plans;
  }
  const curUserPlan = plans.find((plan) => userData?.plans === plan.id);
  const profit = +curUserPlan?.profit_limit.slice(1);
  if (profit < userData.profit) {
    warns.push(
      '⚠️Your current tariff plan has reached its maximum usage limit. Please upgrade your plan to continue using the services.'
    );
  }

  setPlanWarns(warns);
};
export default checkPlanWarns;
