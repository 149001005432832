import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';

import TopActions from '../../../../../common/topActions';
import { getSizeOfDiamond } from '../../../../../helpers/getSizeOfDiamond';
import SelectCard from './selectCard';
import GradientButton from '../../../../../common/gradientButton';
import listForExchangeAPI from '../../../../../api/diamonds/exchanges/listForExchange';
import Toaster from '../../../../../common/toaster';
import { diamondsConstants } from '../../../constants';
import './index.scss';

const Exchange = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [diamond, setDiamond] = useState({});
  const [selectedDiamonds, setSelectedDiamonds] = useState([]);
  const [count, setCount] = useState('');

  const [toast, setToast] = useState({
    message: '',
    type: 'error',
    show: false
  });

  const token = useSelector((state) => state.user.token);
  const diamonds = useSelector((state) => state.diamonds.hashDiamonds);

  const handleExchange = async () => {
    const diamondsToRequest = selectedDiamonds.map((diamond) => {
      return {
        id: 0,
        type: diamond.type
      };
    });

    const diamondsToOffer = [{ id: diamond.idsForExch.freeId, type: diamond.type }];
    const data = {
      diamondsToOffer,
      diamondsToRequest
    };
    try {
      const resp = await listForExchangeAPI(token, navigate, data);
      if (resp.message && resp.message.includes('Success')) {
        setToast({
          message: 'Diamond is listed for exchange',
          type: 'success',
          show: true
        });
        setTimeout(() => {
          navigate('/diamonds/list');
        }, 1000);
        return;
      }
      if (resp.errorCode === 6 || resp.errorCode === 5) {
        setToast({
          message: 'Diamond is locked ',
          type: 'error',
          show: true
        });
      }
    } catch {
      setToast({
        message: 'Sorry, something went wrong. Please try again later.',
        type: 'error',
        show: true
      });
    }
  };

  const handleDeselectItem = (item) => {
    const filteredItems = selectedDiamonds.filter((selectedItem) => {
      return selectedItem.id !== item.id;
    });
    setSelectedDiamonds(filteredItems);
  };

  const handleSelectItem = (item) => {
    setCount((count) => count + 1);
    setSelectedDiamonds([...selectedDiamonds, { ...item, id: count }]);
  };

  useEffect(() => {
    if (location.state?.diamond && Object.values(diamonds).length) {
      setDiamond(location.state?.diamond);
    } else {
      navigate('/diamonds/list');
    }
  }, [location.state]);

  return (
    <div className="exchangeMain">
      <div className="exchangeContainer">
        {toast.show && <Toaster toast={toast} setToast={setToast} />}

        <TopActions left center={'Exchange'} navigateBack />
        <div
          style={{
            backgroundSize: getSizeOfDiamond(diamond.type),
            backgroundImage: `url(${diamond.icon})`
          }}
          className="exchangeDiamond"></div>
        <Divider className="divider" plain>
          <svg
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            className="exchangeIcon"
            xmlns="http://www.w3.org/2000/svg">
            <g id="Item/tabler-icon-transform">
              <path
                id="Vector"
                d="M21 11V8C21 7.46957 20.7893 6.96086 20.4142 6.58579C20.0391 6.21071 19.5304 6 19 6H13M13 6L16 9M13 6L16 3M3 13V16C3 16.5304 3.21071 17.0391 3.58579 17.4142C3.96086 17.7893 4.46957 18 5 18H11M11 18L8 15M11 18L8 21M3 6C3 6.79565 3.31607 7.55871 3.87868 8.12132C4.44129 8.68393 5.20435 9 6 9C6.79565 9 7.55871 8.68393 8.12132 8.12132C8.68393 7.55871 9 6.79565 9 6C9 5.20435 8.68393 4.44129 8.12132 3.87868C7.55871 3.31607 6.79565 3 6 3C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6ZM15 18C15 18.7956 15.3161 19.5587 15.8787 20.1213C16.4413 20.6839 17.2044 21 18 21C18.7956 21 19.5587 20.6839 20.1213 20.1213C20.6839 19.5587 21 18.7956 21 18C21 17.2044 20.6839 16.4413 20.1213 15.8787C19.5587 15.3161 18.7956 15 18 15C17.2044 15 16.4413 15.3161 15.8787 15.8787C15.3161 16.4413 15 17.2044 15 18Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        </Divider>
        <div className="selectedCardsContainer">
          <div className="selectedCardsWrapper">
            {selectedDiamonds.map((diamond, i) => {
              return (
                <SelectCard
                  selected
                  key={diamond.id + '' + i}
                  selectedDiamond={diamond}
                  handleClickDiamond={handleDeselectItem}
                />
              );
            })}
          </div>
        </div>
        <div className="selectText">Select the items you would like to exchange for</div>
        <div className="selectCardsContainer">
          <div className="selectCardsWrapper">
            {diamondsConstants.map((diamond, i) => {
              return (
                <SelectCard
                  key={diamond.id + '' + i}
                  selectedDiamond={diamond}
                  handleClickDiamond={handleSelectItem}
                />
              );
            })}
          </div>
        </div>
        <GradientButton
          handleClickButton={handleExchange}
          text={'Send for exchange'}
          absolute={false}
        />
      </div>
    </div>
  );
};

export default Exchange;
