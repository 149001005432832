import React from 'react';
import PropTypes from 'prop-types';
import { cryptNames } from './constant';
import './index.scss';

const shortedNum = (num) => num.toString().slice(0, num < 0 ? 13 : 12);
const getProfitText = (profit) => (profit < 0 ? `- $${-profit}` : `+ $${profit}`);
const checkProfit = (profit) => (profit < 0 ? 'Buy' : 'Sell');

const HistoryCard = ({ data }) => {
  if (!data) return <div className="cardContainer">Loading...</div>;

  const { currency, quantity, profit, type } = data;

  return (
    <div className="cardContainer">
      <div className="cardInfo">
        <img
          src={`/cryptIcons/${currency}.png`}
          alt={`${currency} icon`}
          width="24px"
          height="24px"
        />
        <div className="cardStats">
          <h1>{currency?.startsWith('dmd') ? 'Diamond' : cryptNames[currency] || currency}</h1>
          <p>
            {currency?.startsWith('dmd') ? checkProfit(profit) : type} • {shortedNum(quantity)}{' '}
            {data?.currency?.startsWith('dmd') ? ' DMD' : data?.currency}
          </p>
        </div>
      </div>
      <p className="profit">{getProfitText(shortedNum(profit))}</p>
    </div>
  );
};

HistoryCard.propTypes = {
  data: PropTypes.shape({
    currency: PropTypes.string,
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    profit: PropTypes.number,
    type: PropTypes.string
  })
};

export default HistoryCard;
