import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import PropTypes from 'prop-types';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js';

const DiamondMiningCard = React.memo(({ selectedDiamond, diamondCardRef, onReady }) => {
  const sceneRef = useRef();
  const cameraRef = useRef();
  const rendererRef = useRef();
  const controlsRef = useRef();
  const diamondsRef = useRef({});
  const lastSelectedDiamondRef = useRef(null);

  const lerpPosition = (object, targetPosition, duration = 1) => {
    const start = { ...object.position };
    const startTime = performance.now();
    const animateLerp = (time) => {
      const elapsed = (time - startTime) / (duration * 600); // Adjusted to use milliseconds
      if (elapsed < 1) {
        object.position.lerpVectors(start, targetPosition, elapsed);
        requestAnimationFrame(animateLerp);
      } else {
        object.position.copy(targetPosition);
        checkAndHideDiamonds();
        if (onReady) onReady();
      }
    };
    requestAnimationFrame(animateLerp);
  };

  const checkAndHideDiamonds = () => {
    const thresholdDistance = 1; // A small threshold to determine visibility
    Object.values(diamondsRef.current).forEach((diamond) => {
      const distanceFromCenter = diamond.position.length();
      diamond.visible = distanceFromCenter <= thresholdDistance;
    });
  };

  const moveDiamondToOppositeSide = (diamond, newDiamondPosition) => {
    const oppositePosition = new THREE.Vector3()
      .subVectors(diamond.position, newDiamondPosition)
      .normalize()
      .multiplyScalar(50);
    lerpPosition(diamond, oppositePosition);
  };

  useEffect(() => {
    const scene = new THREE.Scene();
    sceneRef.current = scene;

    const camera = new THREE.PerspectiveCamera(
      75,
      diamondCardRef.current.clientWidth / diamondCardRef.current.clientHeight,
      0.1,
      1000
    );
    camera.position.set(7, 2, 0);
    cameraRef.current = camera;

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(diamondCardRef.current.clientWidth, diamondCardRef.current.clientHeight);
    renderer.setClearColor(0x000000, 0);
    renderer.shadowMap.enabled = true;
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 0.45;
    diamondCardRef.current.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.screenSpacePanning = false;
    controlsRef.current = controls;

    const loader = new GLTFLoader();
    new RGBELoader().setPath('/textures/').load('blouberg_sunrise_2_1k.hdr', (texture) => {
      texture.mapping = THREE.EquirectangularReflectionMapping;
      scene.environment = texture;

      loader.load('/models/diamonds.glb', (gltf) => {
        const model = gltf.scene;
        scene.add(model);

        model.traverse((child) => {
          if (child.isMesh && ['Red', 'Green', 'Blue', 'Gray'].includes(child.name)) {
            diamondsRef.current[child.name] = child;
          }
        });

        if (Object.keys(diamondsRef.current).length > 0) {
          const initialDiamond = selectedDiamond.name
            ? diamondsRef.current[selectedDiamond.name]
            : Object.values(diamondsRef.current)[0];
          lastSelectedDiamondRef.current = initialDiamond;
          lerpPosition(initialDiamond, new THREE.Vector3(0, 0, 0));
        }

        // Start animation loop
        animate();
      });
    });

    const handleResize = () => {
      const { clientWidth, clientHeight } = diamondCardRef.current;
      renderer.setSize(clientWidth, clientHeight);
      camera.aspect = clientWidth / clientHeight;
      camera.updateProjectionMatrix();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      diamondCardRef.current?.removeChild(renderer.domElement);
      controls.dispose();
      renderer.dispose();
    };
  }, [diamondCardRef.current]);

  useEffect(() => {
    const diamonds = diamondsRef.current;

    Object.values(diamonds).forEach((diamond) => {
      if (selectedDiamond.name === diamond.name) {
        if (lastSelectedDiamondRef.current) {
          moveDiamondToOppositeSide(lastSelectedDiamondRef.current, diamond.position);
        }
        lerpPosition(diamond, new THREE.Vector3(0, 0, 0));
      } else {
        checkAndHideDiamonds();
      }
    });

    lastSelectedDiamondRef.current = diamonds[selectedDiamond.name];
  }, [selectedDiamond]);

  const animate = () => {
    requestAnimationFrame(animate);
    controlsRef.current.update();
    rendererRef.current.render(sceneRef.current, cameraRef.current);
  };

  return null;
});

DiamondMiningCard.displayName = 'DiamondMiningCard';

DiamondMiningCard.propTypes = {
  diamondCardRef: PropTypes.object.isRequired,
  selectedDiamond: PropTypes.object.isRequired,
  onReady: PropTypes.func.isRequired
};

export default DiamondMiningCard;
