import { setSortedDiamonds } from '../../store/slices/diamonds';
import { diamondsInitial } from '../constants';

export const DiamondHelpers = {
  isForSale: (diamonds, diamondData) => {
    const { idsForSave } = diamondData;
    return diamonds[idsForSave?.lockedId]?.isforsave;
  },
  isForExch: (diamonds, diamondData) => {
    const { idsForExch } = diamondData;
    return diamonds[idsForExch?.lockedId]?.isforexch;
  },
  isLocked: (diamonds, diamondsData) => {
    const { idsForSave, idsForExch } = diamondsData;

    return diamonds[idsForExch?.lockedId]?.isforexch || diamonds[idsForSave?.lockedId]?.isforsave;
  },
  filterDiamonds: (diamonds, dispatch) => {
    let data = structuredClone(diamondsInitial);
    for (const diamond in diamonds) {
      let { isforsave, isforexch, idsForSave, idsForExch } = data[diamonds[diamond].type];
      data[diamonds[diamond].type].count++;
      data[diamonds[diamond].type].id = diamonds[diamond].id;

      if (!isforsave) {
        isforsave = diamonds[diamond].isforsave;
        if (diamonds[diamond].isforsave) {
          idsForSave.lockedId = diamonds[diamond].id;
        } else if (!diamonds[diamond].isforexch) {
          idsForSave.freeId = diamonds[diamond].id;
        }
      }

      if (!isforexch) {
        isforexch = diamonds[diamond].isforexch;
        if (diamonds[diamond].isforexch) {
          idsForExch.lockedId = diamonds[diamond].id;
        } else if (!diamonds[diamond].isforsave) {
          idsForExch.freeId = diamonds[diamond].id;
        }
      }
    }
    dispatch(setSortedDiamonds({ diamonds: data }));
  }
};
