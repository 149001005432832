import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getDiamondsAPI from '../../../api/diamonds/getDiamonds';
import getTradesAPI from '../../../api/diamonds/getTrades';
import listForSaleAPI from '../../../api/diamonds/listForSale';
import getOverallIndexAPI from '../../../api/diamonds/mining/getOverallIndex';
import setDiamondOnMiningAPI from '../../../api/diamonds/mining/setDiamondOnMining';

export const initialState = {
  arrayDiamonds: [],
  hashDiamonds: {},
  trades: [],
  sortedDiamonds: {},
  actualDiamond: '',
  loading: true,
  shouldUpdateDiamonds: false
};

export const getDiamondsThunk = createAsyncThunk('diamond', async (data, { rejectWithValue }) => {
  try {
    const response = await getDiamondsAPI(data.token, data.navigate);
    if (response.success === false) {
      return rejectWithValue(response.message || 'Error');
    }

    return { ...response, setToast: data.setToast };
  } catch (err) {
    return rejectWithValue(err.response?.data?.message || 'Error');
  }
});

export const listForSaleThunk = createAsyncThunk(
  'listForSale',
  async (data, { rejectWithValue }) => {
    try {
      const response = await listForSaleAPI(data.token, data.navigate, data.payload);
      if (response.success === false) {
        return rejectWithValue(response.message || 'Error');
      }
      return { ...response, setToast: data.setToast };
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const getTradesThunk = createAsyncThunk('trades', async (data, { rejectWithValue }) => {
  try {
    const response = await getTradesAPI(data.token, data.navigate);
    if (response.success === false) {
      return rejectWithValue(response.message || 'Error');
    }

    return { trades: response, setToast: data.setToast };
  } catch (err) {
    return rejectWithValue(err.response?.data?.message || 'Error');
  }
});

export const getOverallIndexThunk = createAsyncThunk(
  'overallIndex',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getOverallIndexAPI(data.token, data.navigate);

      if (response.success === false) {
        return rejectWithValue(response.message || 'Error');
      }

      return { message: response.message, setToast: data.setToast };
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const setDiamondOnMiningThunk = createAsyncThunk(
  'setDiamondOnMining',
  async (data, { rejectWithValue }) => {
    try {
      const response = await setDiamondOnMiningAPI(data.token, data.navigate, data.index);

      return { message: response.message, success: response.success, setToast: data.setToast };
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const diamondsSlice = createSlice({
  name: 'diamonds',
  initialState,
  reducers: {
    setSortedDiamonds: (state, action) => {
      state.sortedDiamonds = action.payload.diamonds;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDiamondsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDiamondsThunk.fulfilled, (state, action) => {
        state.loading = false;
        try {
          let hashDiamonds = {};
          if (action.payload.data?.diamonds) {
            for (const diamond in action.payload.data?.diamonds) {
              hashDiamonds[action.payload.data.diamonds[diamond].id] =
                action.payload.data.diamonds[diamond];
            }
          }
          state.hashDiamonds = hashDiamonds;
          state.arrayDiamonds = action.payload.data?.diamonds;
        } catch (err) {
          action.payload.setToast({
            message: 'Sorry, something went wrong. Please try again later.',
            type: 'error',
            show: true
          });
        }
      })
      .addCase(getOverallIndexThunk.fulfilled, (state, action) => {
        state.actualDiamond = JSON.parse(action.payload.message)[0].index;
      })
      .addCase(getTradesThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTradesThunk.fulfilled, (state, action) => {
        state.loading = false;
        try {
          state.trades = action.payload.trades;
        } catch (err) {
          action.payload.setToast({
            message: 'Sorry, something went wrong. Please try again later.',
            type: 'error',
            show: true
          });
        }
      })
      .addCase(listForSaleThunk.pending, (state) => {
        state.shouldUpdateDiamonds = false;
      })
      .addCase(listForSaleThunk.fulfilled, (state) => {
        state.shouldUpdateDiamonds = true;
      });
  }
});

export const { setSortedDiamonds } = diamondsSlice.actions;

export default diamondsSlice.reducer;
