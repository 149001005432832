import getDiamondsAPI from '../api/diamonds/getDiamonds';

export const parseDiamonds = async (localStorageToken, navigate) => {
  const res = await getDiamondsAPI(localStorageToken, navigate);
  let hashDiamonds = {};
  if (res.data?.diamonds) {
    for (const diamond in res.data?.diamonds) {
      hashDiamonds[res.data.diamonds[diamond].id] = res.data.diamonds[diamond];
    }
  }
  return hashDiamonds;
};
