import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { useNavigate } from 'react-router-dom';
const TopActions = (props) => {
  const navigate = useNavigate();
  const { left, center, right, tradingLog, navigateBack, marginLeft, rightText } = props;

  const handleCancelButton = () => {
    if (navigateBack) {
      navigate(-1);
      return;
    }
    navigate('/strategy');
  };

  const getRightPartText = () => {
    if (rightText) {
      return rightText;
    }
    return 'Save';
  };

  return (
    <header style={tradingLog && { padding: '10px' }} className="topActions">
      {left && !navigateBack ? (
        <button onClick={handleCancelButton} className="cancel">
          Exit
        </button>
      ) : (
        <svg
          onClick={handleCancelButton}
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none">
          <path
            d="M24.5 11L15.5 20L24.5 29"
            stroke="#699BB6"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      <div style={{ marginLeft }} className="title">
        {center}
      </div>
      <div onClick={right} className="save">
        {right && getRightPartText()}
      </div>
    </header>
  );
};
export default TopActions;

TopActions.propTypes = {
  left: PropTypes.bool,
  center: PropTypes.string,
  right: PropTypes.func || PropTypes.bool,
  tradingLog: PropTypes.bool,
  navigateBack: PropTypes.bool,
  marginLeft: PropTypes.number,
  rightText: PropTypes.string
};
