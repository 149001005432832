import relaxHero from '../../assets/img/relax-hero.png';
import balancedHero from '../../assets/img/balanced-hero.png';
import aggressiveHero from '../../assets/img/aggressive-hero.png';

export const dissapearNumbers = [
  { operation: '+', number: '$0,005', style: 'first' },
  { operation: '+', number: '$0,007', style: 'second' },
  { operation: '-', number: '$0,020', style: 'third' },
  { operation: '+', number: '$0,050', style: 'fourth' }
];

export const diamondsData = [
  {
    name: 'Gray',
    srcModel: '/models/grayDiamond.glb',
    srcImage: '/diamonds/grayDiamond.png',
    description: 'Combine 6 of them to receive a mysterious chest with treasures.',
    number: 0
  },
  {
    name: 'Green',
    srcModel: '/models/greenDiamond.glb',
    srcImage: '/diamonds/greenDiamond.png',
    description: 'Three of them activate a bonus: +20% to deal speed!',
    number: 1
  },
  {
    name: 'Blue',
    srcModel: '/models/blueDiamond.glb',
    srcImage: '/diamonds/blueDiamond.png',
    description: 'Three of them activate a bonus: +20% to both deal speed and value!',
    number: 2
  },
  {
    name: 'Red',
    srcModel: '/models/redDiamond.glb',
    srcImage: '/diamonds/redDiamond.png',
    description: 'Three of them activate a bonus: +20% to deal value!',
    number: 3
  }
];

export const strategyCards = {
  relax: {
    pic: relaxHero,
    title: 'Relaaax',
    description: 'Stable profit with minimal risk'
  },
  balanced: {
    pic: balancedHero,
    title: 'Balanced',
    description: 'Balanced profit with balanced risk'
  },
  aggressive: {
    pic: aggressiveHero,
    title: 'Aggressive',
    description: 'Maximum profit with high risk'
  }
};

export const plansData = {
  0: {
    title: 'Rookie s Path',
    icon: 'plans/rookieIcon.png',
    color: 'simple'
  },
  1: {
    title: 'Golden Key',
    icon: 'plans/goldenIcon.png',
    color: 'gold'
  },
  2: {
    title: 'Royal League',
    icon: 'plans/royalIcon.png',
    color: 'simple'
  },
  3: {
    title: "Dragon's Order",
    icon: 'plans/dragonsIcon.png',
    color: 'gold'
  },
  4: {
    title: 'Crystal Covenant',
    icon: 'plans/crystalIcon.png',
    color: 'simple'
  },
  5: {
    title: 'Mystic Monarchs',
    icon: 'plans/mysticIcon.png',
    color: 'gold'
  }
};
