import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import getExchangesAPI from '../../../api/diamonds/exchanges/getExchanges';
import Toaster from '../../../common/toaster';
import './index.scss';
import ExchangeCard from './exchangeCard';
const ExchangeList = () => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.user);
  const [toast, setToast] = useState({
    message: '',
    type: 'error',
    show: false
  });
  const [exchanges, setExchanges] = useState([]);

  const handleParseExchanges = async () => {
    try {
      const resp = await getExchangesAPI(token, navigate);
      if (Array.isArray(resp)) {
        setExchanges(resp);
      } else {
        setToast({
          message: 'Sorry, something went wrong. Please try again later.',
          type: 'error',
          show: true
        });
      }
    } catch {
      setToast({
        message: 'Sorry, something went wrong. Please try again later.',
        type: 'error',
        show: true
      });
    }
  };

  useEffect(() => {
    handleParseExchanges();
  }, []);

  return (
    <div className="exchangeListContainer">
      {toast.show && <Toaster toast={toast} setToast={setToast} />}
      {exchanges.map((deal) => {
        return (
          <ExchangeCard
            key={deal.id}
            deal={deal}
            handleParseExchanges={handleParseExchanges}
            setToast={setToast}
          />
        );
      })}
    </div>
  );
};
export default ExchangeList;
