import ApiUtils from '../apiUtils';

const getInfoAPI = async (token, navigate, setLoadingHistory, dateEnd, pageNum = 1) => {
  const url = `${ApiUtils.API_BASE_URL}/history/info`;
  const options = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      dateStart: '2022-10-08',
      dateEnd,
      pageNum: pageNum - 1,
      pageSize: 20
    })
  };
  setLoadingHistory(true);
  return await fetch(url, options)
    .then(async (response) => {
      const parsed = await ApiUtils.checkStatus(response, navigate);
      return parsed;
    })
    .then((parsed) => {
      setLoadingHistory(false);
      return parsed.json();
    })
    .catch((err) => err);
};
export default getInfoAPI;
