import React from 'react';

export const descriptions = {
  header: ['Every chest holds a secret.', <br key={0}></br>, ' Open it to reveal it!'],
  gray: 'You found a gray diamond! Collect 6 of them to receive a mysterious chest with treasures.',
  green:
    'Congratulations! You found a green diamond! Collect three more to activate a bonus: +20% to transaction speed!',
  red: 'Congratulations! You found a red diamond! Collect three more to activate a bonus: +20% to transaction value!',
  blue: 'Congratulations! You found a blue diamond! Collect three more to activate a bonus: +20% to both transaction speed and value!'
};
