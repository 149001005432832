import React, { createContext, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Router from './router';

export const AppContext = createContext({
  loading: false,
  setLoading: () => {},
  loadingHistory: false,
  setLoadingHistory: () => {},
  setPlanWarns: () => {},
  planWarns: []
});

const VinzaApp = () => {
  const [loading, setLoading] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [planWarns, setPlanWarns] = useState([]);

  const context = {
    loading,
    setLoading,
    loadingHistory,
    setLoadingHistory,
    planWarns,
    setPlanWarns
  };

  return (
    <AppContext.Provider value={context}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </AppContext.Provider>
  );
};
export default VinzaApp;
