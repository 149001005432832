export const getSizeOfDiamond = (idx) => {
  if (idx === 1) {
    return '80%';
  } else if (idx === 2) {
    return '74%';
  } else if (idx === 3) {
    return '76%';
  } else if (idx > 3 && idx < 8) {
    return '70%';
  }

  return '105%';
};
