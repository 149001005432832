export const infoPoints = [
  {
    num: '1',
    text: 'At the beginning of the game, Vinza provides Bitcoin worth $20.'
  },
  {
    num: '2',
    text: 'The player sets a strategy for automatic trading.'
  },
  {
    num: '3',

    text: 'Strategy choices, а good strategy leads to good income:',
    strategies: [
      {
        key: 'Relax',
        value: 'the market will fall.'
      },
      {
        key: 'Balanced',
        value: 'the market will grow moderately.'
      },
      {
        key: 'Aggressive',
        value: 'the market will surge.'
      }
    ]
  },
  {
    num: '4',

    text: 'Profits are transferred to the specified crypto wallet.'
  },

  {
    num: '5',

    text: 'Use tariff plans to increase your benefits'
  }
];
