import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import './index.scss';

const WithdrawSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="successMain">
      <div className="successContainer">
        <div className="successImage">
          <h2 className="successTitle">Successful Withdrawal</h2>
          <h2 className="successMessage">
            You withdrawal request is being reviewed and will take approximately 12 hours.
          </h2>
        </div>
        <p className="apiMessage">{location.state?.message}</p>
        <button className="continueButton" onClick={() => navigate('/strategy')}>
          Continue
        </button>
      </div>
    </div>
  );
};
export default WithdrawSuccess;
