import React from 'react';

import './index.scss';
const Ellipse = () => {
  return (
    <div className="ellipseContainer">
      <div className="outer"></div>
      <div className="middle"></div>
      <div className="inner"></div>
    </div>
  );
};

export default Ellipse;
