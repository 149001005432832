import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from '../appContext';
import './index.scss';
import checkSettingsWarns from '../../helpers/checkSettingsWarns';
import Curtain from './curtain';
import DiamondMiningCard from './diamondMiningCard';
import { diamondsConstants } from '../diamonds/constants';
import Toaster from '../../common/toaster';
import { setDiamondOnMiningThunk } from '../store/slices/diamonds';
import { Spin } from 'antd';
import { getUserThunk } from '../store/slices/user';
import { diamondsData } from './constants';

const MiningDiamond = () => {
  const { planWarns } = useContext(AppContext);
  const { userData, token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { isUnreadMessages } = useSelector((state) => state.notifications);
  const actualDiamond = useSelector((state) => state.diamonds.actualDiamond);

  const [remainingTime, setRemainingTime] = useState(null);
  const [fontSize, setFontSize] = useState(53);
  const [toast, setToast] = useState({
    message: '',
    type: 'error',
    show: false
  });
  const [selectedDiamond, setSelectedDiamond] = useState(diamondsData[0]);
  const [settingsWarns, setSettingsWarns] = useState([]);
  const [loadingScene, setLoadingScene] = useState(true);
  const [loadingActual, setLoadingActual] = useState(true);
  const diamondCardRef = useRef(null);
  const navigate = useNavigate();
  const minFontSize = 33;

  const handleDiamonds = () => {
    navigate('/diamonds/list');
  };

  const handlePlans = () => {
    navigate('/plans');
  };

  const handleSettings = () => {
    navigate('/settings');
  };

  const handleNotifications = () => {
    navigate('/notifications');
  };

  const updateTimer = () => {
    if (userData.userStartDate && userData.userEndDate) {
      const endDate = new Date(userData.userEndDate);

      const now = Date.now();

      const timeLeft = Math.max(0, endDate - now);

      setRemainingTime(timeLeft);

      if (timeLeft <= 0) {
        localStorage.removeItem('miningStartTime');
        setRemainingTime('00:00');
      }
    }
  };

  const getSizeOfDiamond = (idx) => {
    if (idx > 1 && idx < 4) {
      return {
        width: 56,
        height: 56
      };
    } else if (idx > 3 && idx < 8) {
      return {
        width: 48,
        height: 48
      };
    }
    return {
      width: 64,
      height: 64
    };
  };

  const startMining = async () => {
    const resp = await dispatch(
      setDiamondOnMiningThunk({ token, navigate, index: selectedDiamond.number })
    ).unwrap();
    if (resp.success) {
      const startTime = Date.now();
      localStorage.setItem('miningStartTime', startTime);
      setToast({
        message: 'Diamond mining started',
        type: 'success',
        show: true
      });
      dispatch(getUserThunk({ token, navigate }));
    } else {
      setToast({
        message: resp.message,
        type: 'error',
        show: true
      });
    }
  };

  const selectDiamond = async (diamond) => {
    setSelectedDiamond(diamond);
  };

  const onReady = () => {
    setLoadingScene(false);
  };

  useEffect(() => {
    const strBalance = userData?.balance + '';
    const textLength = strBalance.length;
    if (textLength > 0) {
      const newFontSize = Math.max(minFontSize, Math.min(53, 300 / textLength));
      setFontSize(newFontSize);
    }
  }, [userData?.balance]);

  useEffect(() => {
    if (userData && token) {
      setSettingsWarns(checkSettingsWarns(userData));
    }
  }, [userData]);

  useEffect(() => {
    const timer = setInterval(updateTimer, 1000);
    updateTimer();

    return () => clearInterval(timer);
  }, [userData.userStartDate, userData.userEndDate]);

  const formatRemainingTime = (time) => {
    if (time === null) return '00:00';

    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    setLoadingActual(actualDiamond === '' ? true : false);
  }, [actualDiamond]);

  return (
    <div className="strategyContainer">
      {toast.show && <Toaster toast={toast} setToast={setToast} />}

      <Curtain fontSize={fontSize} />

      <div className="actualDiamond">
        <p>Actual</p>
        {loadingActual ? (
          <Spin />
        ) : (
          <img
            style={{ border: 0 }}
            width={getSizeOfDiamond(actualDiamond.number).width}
            height={getSizeOfDiamond(actualDiamond.number).height}
            src={diamondsConstants[actualDiamond]?.icon}
          />
        )}
        <div className="glowFlame">
          <div className="flame"></div>
        </div>
      </div>

      <div className="strategyViewBlock">
        <div
          ref={diamondCardRef}
          style={{
            width: '100%',
            height: '35dvh',
            overflow: 'hidden',
            background: 'transparent',
            position: 'relative'
          }}
        />
        {loadingScene && (
          <div style={{ position: 'absolute' }}>
            <Spin />
          </div>
        )}
        <DiamondMiningCard
          selectedDiamond={selectedDiamond}
          diamondCardRef={diamondCardRef}
          onReady={onReady}
        />
        {remainingTime === '00:00' ? (
          <button className="startMining" onClick={startMining}>
            Start mining
          </button>
        ) : remainingTime === null ? (
          <div className="countdownTimer">
            <button className="startMining" disabled>
              Pending
            </button>
          </div>
        ) : (
          <div className="countdownTimer">
            <button className="startMining" disabled>
              Mining...
            </button>
            <p>{formatRemainingTime(remainingTime)}</p>
          </div>
        )}

        <div className="switcherContainer">
          <div className="switcherBlock">
            <div className="switcherLine">
              <div
                className={`neutralOption ${
                  selectedDiamond.name === 'Gray' ? 'selected' : ''
                } option`}
                onClick={() => {
                  selectDiamond(diamondsData[0]);
                }}
              />
              <div
                className={`relaxOption ${
                  selectedDiamond.name === 'Green' ? 'selected' : ''
                } option`}
                onClick={() => {
                  selectDiamond(diamondsData[1]);
                }}
              />
              <div
                className={`balancedOption ${
                  selectedDiamond.name === 'Blue' ? 'selected' : ''
                } option`}
                onClick={() => {
                  selectDiamond(diamondsData[2]);
                }}
              />

              <div
                className={`aggressiveOption ${
                  selectedDiamond.name === 'Red' ? 'selected' : ''
                } option`}
                onClick={() => {
                  selectDiamond(diamondsData[3]);
                }}
              />
            </div>

            <div className="swithcerLabels">
              <div>
                <div className="labelLine" />
                <p className="labelText">Gray</p>
              </div>
              <div>
                <div className="labelLine" />
                <p className="labelText">Green</p>
              </div>
              <div>
                <div className="labelLine" />
                <p className="labelText">Blue</p>
              </div>

              <div>
                <div className="labelLine" />
                <p className="labelText">Red</p>
              </div>
            </div>
            <div className="menuIcons">
              <div onClick={handleDiamonds} className="diamonds" />
              <div onClick={handlePlans} className="plans">
                {planWarns.length ? <div className="notificationDot" /> : <></>}
              </div>
              <div onClick={handleSettings} className="settings">
                {settingsWarns.length ? <div className="notificationDot" /> : <></>}
              </div>
              <div onClick={handleNotifications} className="notifications">
                {isUnreadMessages && <div className="notificationDot" />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiningDiamond;
