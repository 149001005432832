import React, { useEffect, useState } from 'react';

import { Tabs } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './index.scss';
import Trades from './trades';
import DiamondsList from './diamondsList';
import TopActions from '../../common/topActions';
import { coolNicknames, topActionsNames } from './constants';
import ExchangeList from './exchangesList';
import { getDiamondsThunk } from '../store/slices/diamonds';
import { DiamondHelpers } from './helpers';

const DiamondsMain = () => {
  const [tab, setTab] = useState('1');
  const [showGif, setShowGif] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const diamonds = useSelector((state) => state.diamonds.hashDiamonds);
  const shouldUpdateDiamonds = useSelector((state) => state.diamonds.shouldUpdateDiamonds);
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { filterDiamonds } = DiamondHelpers;
  const tabItems = [
    {
      key: '1',
      label: 'Diamonds',
      children: <DiamondsList />
    },
    {
      key: '2',
      label: 'Trades',
      children: <Trades />
    },
    {
      key: '3',
      label: 'Exchanges',
      children: <ExchangeList />
    }
  ];

  const getTab = (idx) => {
    if (idx === '1') {
      return 'list';
    }
    if (idx === '2') {
      return 'trades';
    }
    return 'exchanges';
  };

  useEffect(() => {
    if (location?.pathname === '/diamonds/trades') {
      setTab('2');
    }
    if (location?.pathname === '/diamonds/exchanges') {
      setTab('3');
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (
      token &&
      (location?.pathname === '/diamonds/list' || location?.pathname === '/diamonds/exchanges')
    ) {
      dispatch(getDiamondsThunk({ token, navigate }));
    }
  }, [token, location?.pathname]);

  useEffect(() => {
    const gifInterval = setInterval(() => {
      setShowGif(true);
      setTimeout(() => {
        setShowGif(false);
      }, 2000);
    }, 5000);

    return () => clearInterval(gifInterval);
  }, []);

  useEffect(() => {
    if (diamonds || shouldUpdateDiamonds) {
      filterDiamonds(diamonds, dispatch);
    }
  }, [diamonds, shouldUpdateDiamonds]);

  return (
    <div className="diamondsMain">
      <div className="actionsContainer">
        <TopActions left marginLeft={24} center={topActionsNames[tab - 1]} />
      </div>
      <div className="eyeContainer">
        {showGif && (
          <div className="eyeBlock">
            <img className="eyeWatching" src="/gifs/eyeGif.gif" width={50} height={30}></img>
            <p>{coolNicknames[Math.floor(Math.random() * coolNicknames.length)]}</p>
          </div>
        )}
      </div>
      <Tabs
        activeKey={tab}
        items={tabItems}
        centered
        onChange={(e) => {
          navigate(`/diamonds/${getTab(e)}`);
          setTab(e.toString());
        }}
      />
    </div>
  );
};
export default DiamondsMain;
