import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import SignUp from './auth/signUp';
import SignIn from './auth/signIn';
import History from './history';
import Info from './info';
import Settings from './settings';
import Plans from './plans';
import Withdraw from './withdraw';
import WithdrawSuccess from './withdraw/withdrawSuccess';
import Notifications from './notifications';
import CustomNotification from './notifications/customNotification';
import Diamonds from './diamonds';
import Combining from './combining';

import Toaster from '../common/toaster';
import checkPlanWarns from '../helpers/checkPlanWarns';
import BoxPage from './box';
import { AppContext } from './appContext';
import { getUserThunk, updateToken } from './store/slices/user';
import { getNotificationsThunk } from './store/slices/notifications';
import Diamond from './diamonds/diamondsList/diamondScreen';
import Exchange from './diamonds/diamondsList/diamondScreen/exchange';
import GameGoal from './settings/gameGoal';
import MiningDiamond from './home/diamondMining';
import { getOverallIndexThunk } from './store/slices/diamonds';

function Router() {
  const { setPlanWarns } = useContext(AppContext);
  const dispatch = useDispatch();
  const navigate = useNavigate('');
  const location = useLocation();

  const { userData } = useSelector((state) => state.user);
  const { box } = useSelector((state) => state.notifications);

  const [intervalStore, setIntervalStore] = useState('');
  const [toast, setToast] = useState({
    message: '',
    type: 'error',
    show: false
  });

  const localStorageToken = localStorage.getItem('userToken');

  const setVHProperty = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  const planPatsCheck = () => {
    if (location.pathname !== '/plans' || location.pathname === '/') {
      return true;
    }
    return false;
  };
  const parseUserInfo = async () => {
    try {
      dispatch(getUserThunk({ token: localStorageToken, navigate, setToast }));
      dispatch(getOverallIndexThunk({ token: localStorageToken, navigate }));
    } catch (error) {
      setToast({
        message: 'Sorry, something went wrong. Please try again later.',
        type: 'error',
        show: true
      });
    }
  };

  const filterRoutesForAutoGetUser = () => {
    if (location.pathname === '/plans' || location.pathname === '/settings') {
      return true;
    }
  };

  useEffect(() => {
    setVHProperty();
    document.addEventListener(
      'touchmove',
      function (event) {
        if (event.scale !== 1) {
          event.preventDefault();
        }
      },
      false
    );
    window.addEventListener('resize', () => {
      setVHProperty();
    });
  }, []);

  useEffect(() => {
    if (localStorageToken) {
      dispatch(updateToken({ token: localStorageToken }));
      try {
        parseUserInfo();
        if (location.pathname !== '/customNotification') {
          dispatch(
            getNotificationsThunk({
              token: localStorageToken,
              navigate,
              pathname: location.pathname,
              setToast
            })
          );
        }
      } catch (error) {
        setToast({
          message: 'Sorry, something went wrong. Please try again later.',
          type: 'error',
          show: true
        });
      }
      if (!intervalStore && !filterRoutesForAutoGetUser()) {
        setIntervalStore(setInterval(parseUserInfo, 15000));
      }
    } else if (location.pathname !== '/signUp' && location.pathname !== '/signIn') {
      navigate('/signIn');
    }

    if (location.pathname === '/signIn' || filterRoutesForAutoGetUser()) {
      clearInterval(intervalStore);
      setIntervalStore('');
    }
  }, [localStorageToken, location]);

  useEffect(() => {
    if (userData.plans !== undefined && localStorageToken && planPatsCheck()) {
      checkPlanWarns(userData, localStorageToken, navigate, setPlanWarns);
    }
  }, [userData.plans]);

  useEffect(() => {
    if (box > 0) {
      navigate('/box');
    }
  }, [box]);

  return (
    <>
      {toast.show && <Toaster toast={toast} setToast={setToast} />}
      {localStorageToken ? (
        <Routes>
          <Route path="/history" element={<History />} />
          <Route path="/info" element={<Info />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/customNotification" element={<CustomNotification />} />
          <Route path="/box" element={<BoxPage />} />
          <Route path="/gameGoal" element={<GameGoal />} />
          <Route path="/diamonds/:id" element={<Diamond />} />
          <Route path="/combining" element={<Combining />} />
          <Route path="/diamonds/list" element={<Diamonds />} />
          <Route path="/diamonds/trades" element={<Diamonds />} />
          <Route path="/diamonds/exchanges" element={<Diamonds />} />
          <Route path="/diamonds/exchange" element={<Exchange />} />

          {userData?.profit > 10 && (
            <>
              <Route path="/withdraw" element={<Withdraw />} />
              <Route path="/withdrawSuccess" element={<WithdrawSuccess />} />
            </>
          )}

          <Route path="/" element={<MiningDiamond />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/signIn" element={<SignIn />} />
          <Route path="/info" element={<Info />} />
          <Route path="*" element={<Navigate to="/signIn" />} />
        </Routes>
      )}
    </>
  );
}

export default Router;
