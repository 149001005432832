import React from 'react';

export const diamondsConstants = [
  {
    type: 0,
    title: 'Gray',
    icon: '/diamonds/grayDiamond.png',
    description: 'Collect 6 of them to receive a mysterious chest with treasures.'
  },
  {
    type: 1,
    title: 'Green',
    icon: '/diamonds/greenDiamond.png',
    description: 'Three of them activate a bonus: +20% to deal speed!'
  },
  {
    type: 2,
    title: 'Red',
    icon: '/diamonds/redDiamond.png',
    description: 'Three of them activate a bonus: +20% to deal value!'
  },
  {
    type: 3,
    title: 'Blue',
    icon: '/diamonds/blueDiamond.png',
    description: 'Three of them activate a bonus: +20% to both deal speed and value!'
  },
  {
    type: 4,
    title: 'Shining Victory',
    icon: '/diamonds/shiningVictory.png',
    description: 'Activate new bonus: +20% to deal speed!'
  },
  {
    type: 5,
    title: 'Fiery Success ',
    icon: '/diamonds/fierySuccess.png',
    description: 'Activate new bonus:  +20% to deal value!'
  },
  {
    type: 6,
    title: 'Tide of Success',
    icon: '/diamonds/tideOfSuccess.png',
    description: 'Activate new bonus: +20% to both deal speed and value!'
  },
  {
    type: 7,
    title: 'Gold',
    icon: '/diamonds/goldDiamond.png',
    description: [
      'The annual draw of a rare diamond.',
      <br key={1}></br>,
      'Gain 24-hour access to exclusive deals with a generous $50,000.',
      <br key={2}></br>,
      '$1,000 potential profit per each deal.'
    ]
  }
];

export const coolNicknames = [
  'ShadowHawk',
  'CyberWolf',
  'GhostRider',
  'VortexViper',
  'SkyGazer',
  'BlazeKing',
  'ThunderStrike',
  'NeoNinja',
  'QuantumBeast',
  'RogueArrow',
  'SteelSaber',
  'MysticWizard',
  'DarkKnight',
  'PhoenixRise',
  'SpectralTiger',
  'IronCrusader',
  'ValkyrieSky',
  'FrostByte',
  'NebulaNova',
  'CosmoDragon'
];

export const diamondsInitial = {
  0: {
    count: 0,
    id: -1,
    type: 0,
    idsForSave: {
      lockedId: -1,
      freeId: -1
    },
    idsForExch: {
      lockedId: -1,
      freeId: -1
    }
  },
  1: {
    count: 0,
    id: -1,
    type: 1,
    idsForSave: {
      lockedId: -1,
      freeId: -1
    },
    idsForExch: {
      lockedId: -1,
      freeId: -1
    }
  },
  2: {
    count: 0,
    id: -1,
    type: 2,
    idsForSave: {
      lockedId: -1,
      freeId: -1
    },
    idsForExch: {
      lockedId: -1,
      freeId: -1
    }
  },
  3: {
    count: 0,
    id: -1,
    type: 3,
    idsForSave: {
      lockedId: -1,
      freeId: -1
    },
    idsForExch: {
      lockedId: -1,
      freeId: -1
    }
  },
  4: {
    count: 0,
    id: -1,
    type: 4,
    idsForSave: {
      lockedId: -1,
      freeId: -1
    },
    idsForExch: {
      lockedId: -1,
      freeId: -1
    }
  },
  5: {
    count: 0,
    id: -1,
    type: 5,
    idsForSave: {
      lockedId: -1,
      freeId: -1
    },
    idsForExch: {
      lockedId: -1,
      freeId: -1
    }
  },
  6: {
    count: 0,
    id: -1,
    type: 6,
    idsForSave: {
      lockedId: -1,
      freeId: -1
    },
    idsForExch: {
      lockedId: -1,
      freeId: -1
    }
  },
  7: {
    count: 0,
    id: -1,
    type: 7,
    idsForSave: {
      lockedId: -1,
      freeId: -1
    },
    idsForExch: {
      lockedId: -1,
      freeId: -1
    }
  }
};

export const topActionsNames = ['Diamonds', 'Trades', 'Exchanges'];
