import React from 'react';

import { Link } from 'react-router-dom';

const ErrorCode = {
  USER_HISS_KEY_MISSING: 10,
  LICENSE_VALID: 0,
  LICENSE_EXPIRED: 3,
  LICENSE_STATUS_FALSE: 4,
  LICENSE_RESPONSE_NOT_OK: 5,
  LICENSE_STATUS_MISSING: 6,
  TELEGRAM_USERNAME_MISSING: 30,
  TELEGRAM_USERNAME_MISSING_2: 31,
  TELEGRAM_USERNAME_MISSING_3: 32,
  TELEGRAM_USERNAME_NOT_SUBSCRIBED: 40,
  BINANCE_API_KEY_MISSING: 20,
  BINANCE_API_KEY_MISSING_2: 21,
  BINANCE_API_KEY_HAS_STARS: 22,
  BINANCE_SECRET_KEY_MISSING: 23,
  BINANCE_SECRET_KEY_MISSING_2: 24,
  BINANCE_SECRET_KEY_HAS_STARS: 25,
  BINANCE_SPOT_MARGIN_TRADING_DISABLED: 26
};

export const errorMessages = {
  [ErrorCode.USER_HISS_KEY_MISSING]:
    "User's hiss key not set.Please specify in the Account section",
  [ErrorCode.LICENSE_VALID]: 'License is valid',
  [ErrorCode.LICENSE_EXPIRED]: 'License has expired',
  [ErrorCode.LICENSE_STATUS_FALSE]: [
    'Please enter the HISS key in ',
    <Link key={1} to={'/settings'}>
      {' '}
      Settings
    </Link>
  ],
  [ErrorCode.LICENSE_RESPONSE_NOT_OK]: 'License server response not okay',
  [ErrorCode.LICENSE_STATUS_MISSING]: 'License status missing',
  [ErrorCode.TELEGRAM_USERNAME_MISSING]: [
    'Telegram username not set in ',
    <Link key={1} to={'/settings'}>
      {' '}
      Settings
    </Link>
  ],
  [ErrorCode.TELEGRAM_USERNAME_MISSING_2]: [
    'Telegram username not set in ',
    <Link key={1} to={'/settings'}>
      {' '}
      Settings
    </Link>
  ],
  [ErrorCode.TELEGRAM_USERNAME_MISSING_3]: [
    'Telegram username not set in ',
    <Link key={1} to={'/settings'}>
      {' '}
      Settings
    </Link>
  ],
  [ErrorCode.TELEGRAM_USERNAME_NOT_SUBSCRIBED]: [
    "You've set a username but haven't subscribed to the bot ",
    <a href="https://t.me/VinzaBot" key={2}>
      @VinzaBot
    </a>,
    ' Please type /start in the chat '
  ],
  [ErrorCode.BINANCE_API_KEY_MISSING]: '',
  [ErrorCode.BINANCE_API_KEY_MISSING_2]: '',
  [ErrorCode.BINANCE_API_KEY_HAS_STARS]: '',
  [ErrorCode.BINANCE_SECRET_KEY_MISSING]: '',
  [ErrorCode.BINANCE_SECRET_KEY_MISSING_2]: '',
  [ErrorCode.BINANCE_SECRET_KEY_HAS_STARS]: '',
  [ErrorCode.BINANCE_SPOT_MARGIN_TRADING_DISABLED]:
    'Binance Enable Spot and Margin Trading in API enableSpotAndMarginTrading'
};
