import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getUserAPI from '../../../api/user/getUser';

export const initialState = {
  token: '',
  userData: {},
  loading: false
};

export const getUserThunk = createAsyncThunk('user', async (data, { rejectWithValue }) => {
  try {
    const response = await getUserAPI(data.token, data.navigate);
    if (response.success === false) {
      return rejectWithValue(response.message || 'Error');
    }

    return { ...response, setToast: data.setToast };
  } catch (err) {
    return rejectWithValue(err.response?.data?.message || 'Error');
  }
});

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.userData = action.payload.info;
    },
    updateToken: (state, action) => {
      state.token = action.payload.token;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserThunk.fulfilled, (state, action) => {
        if (action.payload?.success === false) {
          state.messageType = 'warning';
          state.message = 'Try Agin';
        }
        state.loading = false;
        try {
          state.userData = JSON.parse(action.payload?.message).info;
        } catch (err) {
          action.payload.setToast({
            message: 'Sorry, something went wrong. Please try again later.',
            type: 'error',
            show: true
          });
        }
      })
      .addCase(getUserThunk.rejected, (state, action) => {
        if (action.payload?.success === false) {
          state.messageType = 'warning';
          state.message = 'Try Agin';
        }
        state.message = 'Something went wrong';
        state.showMessage = true;
        state.loading = false;
      });
  }
});

export const { updateUser, updateToken } = userSlice.actions;

export default userSlice.reducer;
