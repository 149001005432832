import React from 'react';

import './index.scss';
import TopActions from '../../../common/topActions';
const GameGoal = () => {
  return (
    <div className="gameGoalMain">
      <div className="gameGoalContainer">
        <TopActions left center={'Game Goal'} marginLeft={26} navigateBack />
        <h1 className="gameGoalHeader">The goal of the game &quot;Vinza&quot;</h1>
        <p className="gameGoalText">
          Maximize profit through transactions, outpacing competitors both in speed and volume of
          these transactions. Tariff plans and diamonds, as well as the choice of strategy, play a
          crucial role: the tariff plan affects the speed of transaction execution and their size,
          while diamonds additionally accelerate this process and increase the size of transactions.
          Thus, success in the game requires not only a strategic approach but also effective
          management of the tariff plan and diamonds.
        </p>
        <p className="gameGoalText">
          Game Vinza, priced at $5, provides participants with the opportunity to gain both money
          and enjoyment from speculation. It is important to adhere to the following rules:
        </p>
        <ol>
          <li className="gameGoalText">Politeness is welcome.</li>
          <li className="gameGoalText">Speculations are mandatory.</li>
          <li className="gameGoalText">
            {' '}
            Any form of cheating will lead to exclusion from the game.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default GameGoal;
