import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import TopActions from '../../common/topActions';
// import { AppContext } from '../appContext';
import NotificationCard from './notificationCard';
import readNotificationsAPI from '../../api/user/readNotifications';
import { getNotificationsThunk } from '../store/slices/notifications';
import Toaster from '../../common/toaster';
const Notifications = () => {
  // const { notifications } = useContext(AppContext);
  const [toast, setToast] = useState({
    message: '',
    type: 'error',
    show: false
  });
  const notifications = useSelector((state) => state.notifications?.notifications);
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleMarkAll = async () => {
    await readNotificationsAPI(token, navigate, { body: { markAll: true } });
    dispatch(
      getNotificationsThunk({
        token,
        navigate,
        pathname: location.pathname,
        setToast
      })
    );
  };
  return (
    <div className="notificationMain">
      <div className="notificationContainer">
        <TopActions left center="Notifications" />
        {toast.show && <Toaster toast={toast} setToast={setToast} />}
        <button className="markAll" onClick={handleMarkAll}>
          Mark all as read
        </button>
        {notifications?.map((notificationSection, index) => {
          return (
            <NotificationCard
              key={notificationSection.date + index}
              date={notificationSection.date}
              currentDateNotifications={notificationSection.notifications}
            />
          );
        })}
      </div>
    </div>
  );
};
export default Notifications;
