// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-height: calc(var(--vh, 1dvh) * 100);
  touch-action: none;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.top {
  background-color: blue;
}
.bottom {
  background-color: blue;
  position: absolute;
  bottom: 0;
  width: 100%;
}
/* Delete me before test */
body > iframe { display: none }`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT;wEACsE;EACtE,mCAAmC;EACnC,kCAAkC;EAClC,SAAS;EACT,UAAU;EACV,iBAAiB;EACjB,uCAAuC;EACvC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,+EAA+E;AACjF;;AAEA;EACE,sBAAsB;AACxB;AACA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;AACA,0BAA0B;AAC1B,gBAAgB,cAAc","sourcesContent":["* {\n  box-sizing: border-box;\n}\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',\n    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  margin: 0;\n  padding: 0;\n  min-height: 100vh;\n  min-height: calc(var(--vh, 1dvh) * 100);\n  touch-action: none;\n  overflow: hidden;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\n\n.top {\n  background-color: blue;\n}\n.bottom {\n  background-color: blue;\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n}\n/* Delete me before test */\nbody > iframe { display: none }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
