import { errorMessages } from './constant';

export const getBoxes = (box) => {
  if (box > 0) {
    return box;
  }
  return 0;
};

export const getStatusNotis = (statusArray, parsedNotifications) => {
  for (let i = 0; i < statusArray.length; i++) {
    parsedNotifications.push({
      desc: errorMessages[statusArray[i]],

      img: '',
      time: '',
      id: statusArray[i]
    });
  }
};

export const getUserNotifications = (
  userMessages,
  isThereCustomNotification,
  optionsDate,
  notificationsByDate
) => {
  for (let i = 0; i < userMessages.length; i++) {
    const userMessageDate = new Date(userMessages[i].createdAt);
    const passedDate = new Date(
      userMessageDate.getTime() - userMessageDate.getTimezoneOffset() * 60000
    );
    const today = new Date();
    if (userMessages[i].id === 88) {
      isThereCustomNotification = {
        exist: true,
        id: userMessages[i].id,
        message: userMessages[i].text
      };
    }
    const isToday =
      passedDate.getDate() === today.getDate() &&
      passedDate.getMonth() === today.getMonth() &&
      passedDate.getFullYear() === today.getFullYear();

    if (userMessages[i] && passedDate.toLocaleString) {
      const formattedDate = passedDate.toLocaleString('en-US', optionsDate);

      const notification = {
        desc: userMessages[i]?.text,
        img: '',
        time: isToday ? 'Today ' : formattedDate,
        read: userMessages[i]?.read,
        id: userMessages[i]?.id,
        isUserMessage: true
      };

      const dateKey = isToday ? 'Today' : formattedDate;
      if (!notificationsByDate[dateKey]) {
        notificationsByDate[dateKey] = [];
      }
      notificationsByDate[dateKey].push(notification);
    }
  }
};
