// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Bitter:wght@400;700;900&family=Crimson+Pro:wght@700;900&family=Noto+Serif:wght@900&family=Roboto:wght@400;500;700;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circularProgressSvg {
  filter: brightness(0) invert(1);
}

.circularProgressContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  background: rgba(0, 47, 72, 0.1);
  border-radius: 50px;
}
@media (max-height: 700px) {
  .circularProgressContainer {
    margin-top: 40px;
  }
}
.circularProgressContainer .circularProgress {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.circularProgressContainer .circularProgressText {
  color: white;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20%;
  padding-right: 10px;
  margin-left: 10px;
  opacity: 0.8;
}`, "",{"version":3,"sources":["webpack://./src/modules/home/curtain/index.scss","webpack://./src/scss/fonts.scss"],"names":[],"mappings":"AAEA;EACI,+BAAA;AAAJ;;AAGA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EAGA,gBAAA;EAIA,gCAAA;EACA,mBAAA;AALJ;AACI;EAPJ;IAQQ,gBAAA;EAEN;AACF;AACI;EACI,aAAA;EACA,QAAA;EAGA,mBAAA;EACA,uBAAA;AADR;AAGI;EACI,YAAA;EACA,kBAAA;EACA,iCCxBC;EDyBD,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,YAAA;AADR","sourcesContent":["@import '../../../scss/fonts.scss';\n\n.circularProgressSvg{\n    filter: brightness(0) invert(1);\n    // stroke-width: 2;\n}\n.circularProgressContainer{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    // width: 20px;\n    \n    margin-top: 35px;\n    @media (max-height: 700px){\n        margin-top: 40px;\n    }\n    background: rgba(0, 47, 72, 0.10);\n    border-radius: 50px;\n    .circularProgress{\n        display: flex;\n        gap: 5px;\n\n\n        align-items: center;\n        justify-content: center;\n    }\n    .circularProgressText{\n        color: white;\n        text-align: center;\n        font-family: $Roboto;\n        font-size: 12px;\n        font-style: normal;\n        font-weight: 500;\n        line-height: 20%; \n        padding-right: 10px;\n        margin-left: 10px;\n        opacity: 0.8;\n    }\n}","@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Bitter:wght@400;700;900&family=Crimson+Pro:wght@700;900&family=Noto+Serif:wght@900&family=Roboto:wght@400;500;700;900&display=swap');\n$Bitter: 'Bitter', serif;   \n$Crimson-Pro: 'Crimson Pro', serif;\n$Alfa-Slab-One: 'Alfa Slab One', serif;\n$Noto-Serif: 'Noto Serif', serif;\n$Roboto: 'Roboto', sans-serif;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
