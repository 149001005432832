import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';

import './index.scss';
import TopActions from '../../common/topActions';
import Toaster from '../../common/toaster';
import makeWithdrawalAPI from '../../api/user/withdrawal';

const Withdraw = () => {
  const { token } = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [withdraw, setWithdraw] = useState(10);
  const [network, setNetwork] = useState('Tron TRC20 (TRX)');
  const [cryptoWallet, setCryptoWallet] = useState('');
  const [selectedAgreement, setSelectedAgreement] = useState(false);
  const [toast, setToast] = useState({
    message: '',
    type: 'error',
    show: false
  });
  const userData = useSelector((state) => state.user?.userData);
  const navigate = useNavigate();

  const handleWithdraw = async () => {
    if (userData?.plans < 2) {
      setIsModalOpen(true);
      return;
    }

    if (selectedAgreement) {
      const data = {
        address: cryptoWallet,
        amount: withdraw
      };
      try {
        const resp = await makeWithdrawalAPI(token, navigate, data);
        if (resp.success) {
          navigate('/withdrawSuccess', { state: { message: resp.message } });
        }
      } catch (err) {
        // console.log(err);
      }
    } else {
      setToast({
        message: 'Please accept the agreement before withdrawing.',
        type: 'error',
        show: true
      });
    }
  };

  const handleSelectAgreement = () => {
    setSelectedAgreement(!selectedAgreement);
  };

  const handleMaxButton = () => {
    setWithdraw(userData?.balance - 20);
  };
  useEffect(() => {
    if (userData) {
      setCryptoWallet(userData.trc);
    }
  }, [userData]);
  return (
    <div className="withdrawMain">
      {toast.show && <Toaster toast={toast} setToast={setToast} />}
      <Modal
        title="Withdraw"
        open={isModalOpen}
        onOk={() => (window.location.href = 'https://buy.stripe.com/8wM14ugvn8DGb3GbIQ')}
        centered
        styles={{
          body: {
            backgroundColor: '#07405E',
            color: 'white'
          },
          header: {
            backgroundColor: '#07405E',
            color: 'white'
          },
          footer: {
            backgroundColor: '#07405E',
            color: 'white'
          }
        }}
        onCancel={() => setIsModalOpen(false)}
        classNames="withdrawModal">
        <p>
          To proceed with the translation, a minimum tariff plan is Royal League. Please ensure that
          your transaction generates this or higher tariff plan
        </p>
      </Modal>
      <div className="withdrawContainer">
        {' '}
        <TopActions left center="Withdrawal" />
        <div className="balanceBlock">
          <h2>AVAILABLE</h2>
          <p>
            <span className="dollarSign">$</span> {(userData?.balance - 20)?.toFixed(4)}
          </p>
        </div>
        <div className="withdrawInputContainer ">
          <label htmlFor="withdraw">Withdraw</label>
          <div className="withdrawInputBlock">
            <input
              value={+withdraw?.toFixed(4) || ''}
              onChange={(e) => {
                setWithdraw(e.target.value);
              }}
              className="withdrawInput"
              type="number"
              id="withdraw"
            />
            <p className="currencyWithdraw">USDT</p>
            <button onClick={() => handleMaxButton()} className="maxWithdraw">
              max
            </button>
          </div>
        </div>
        <p className="minimumAmount">Minimum withdrawal amount: 10 USDT</p>
        <h2 className="generalLabel">CRYPTO WALLET</h2>
        <div className="cryptoWalletBlock">
          <div className="inputContainer">
            <label htmlFor="network">Network</label>

            <input
              value={network || ''}
              onChange={(e) => {
                setNetwork(e.target.value);
              }}
              disabled
              className="withdrawInput"
              type="text"
              id="withdraw"
            />
          </div>
          <div className="inputContainer ">
            <label htmlFor="name">USDT Address for withdraw</label>
            <input
              value={cryptoWallet || ''}
              onChange={(e) => {
                setCryptoWallet(e.target.value);
              }}
              className="withdrawInput"
              type="text"
              id="telegramName"
            />
          </div>
          <div className="agreementBlock">
            <div
              onClick={() => handleSelectAgreement()}
              className={`agreementBtn ${selectedAgreement && 'selectedAgreement'}`}
            />
            <div className="agreementTextBlock">
              <p className="agreementText">
                I’m understand that this address of Tether USD (USDT) token is on Tron TRC20 (TRX)
                Network.
              </p>
              <p className="agreementText">
                If the address is incorrectly specified, tokens may be lost.
              </p>
            </div>
          </div>
        </div>
        <button className="withdrawButton" onClick={handleWithdraw}>
          Withdrawal
        </button>
      </div>
    </div>
  );
};
export default Withdraw;
