import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';

import './index.scss';
import { infoPoints } from './constant';
import TopActions from '../../common/topActions';
const Info = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLetsStart = () => {
    if (!localStorage.getItem('passedInstruction')) {
      localStorage.setItem('passedInstruction', true);
      navigate('/app');
    }
  };

  return (
    <div className="infoMain">
      <div className="infoContainer">
        {location.state?.fromStrategy && <TopActions left center="Info" />}
        <div className="logo">
          <p className="welcome">welcome to</p>
          <h2 className="title">Vinza</h2>
        </div>
        <div className="optionsList">
          {infoPoints.map((point) => {
            return (
              <div
                key={point.num + point.text}
                className={point.strategies ? 'pointBlockStrategy' : 'pointBlock'}>
                <div className="pointNum">{point.num}</div>

                {point.strategies ? (
                  <div className="pointText">
                    <p>{point.text}</p>
                    {point.strategies.map(({ key, value }) => (
                      <div className={key} key={key}>
                        <div>{key}</div>
                        <div>{value}</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="pointText">{point.text}</div>
                )}
              </div>
            );
          })}
        </div>
        {!location.state?.fromStrategy && (
          <Button type="primary" onClick={handleLetsStart} className="letsStartButton">
            Let&apos;s strat
          </Button>
        )}
      </div>
    </div>
  );
};
export default Info;
