import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import signUpAPI from '../../../api/auth/signUp';
import Toaster from '../../../common/toaster';
import './index.scss';

const SignUp = () => {
  const [toast, setToast] = useState({
    message: '',
    type: 'error',
    show: false
  });

  const navigate = useNavigate();

  const handleRegistration = async (values) => {
    const extendedVal = { ...values, username: values.name?.toLowerCase() };
    const result = await signUpAPI(extendedVal);
    if (!result.success) {
      const errorMessage = 'Неправильные данные!';
      setToast({ message: errorMessage, type: 'error', show: true });
      return;
    }
    navigate('/signIn', {
      state: {
        message: 'Вы успешно зарегистрировались! Войдите в аккаунт.',
        type: 'success',
        show: true
      }
    });
  };
  return (
    <div className="registrationFormContainer">
      {toast.show && <Toaster toast={toast} setToast={setToast} />}
      <div className="registrationCard">
        <h1 className="cardTitle">Vinza</h1>
        <div className="heroPic" />
        <Form layout="vertical" onFinish={handleRegistration}>
          <div className="signUpTitle">Sign Up</div>
          <div className="signInMessage">
            Already have an account?{' '}
            <Link to="/signIn" className="signInLink">
              Sign in
            </Link>
          </div>
          <Form.Item
            name="name"
            rules={[
              { required: true, message: 'Please input your username!' },
              {
                min: 2,
                message: 'Username must be at least 2 characters long'
              },
              {
                max: 55,
                message: 'Username cannot be longer than 55 characters'
              }
            ]}>
            <Input prefix={<div className="userIcon" />} placeholder="Name" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your Email!' },
              { type: 'email', message: 'Invalid email address' },
              {
                max: 40,
                message: 'Email cannot be longer than 40 characters'
              }
            ]}>
            <Input prefix={<div className="emailIcon" />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Please input your password!' },
              {
                min: 4,
                message: 'Password must be at least 4 characters long'
              },
              {
                max: 26,
                message: 'Value should be less than 26 character'
              }
            ]}>
            <Input.Password prefix={<div className="lockIcon" />} placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="registrationButton">
              Sign Up
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default SignUp;
