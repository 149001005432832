import React, { useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Toaster from '../../../common/toaster';
import signInAPI from '../../../api/auth/signIn';
// import { AppContext } from '../../appContext';
import './index.scss';
import { updateUser, updateToken } from '../../store/slices/user';

const SignIn = () => {
  // const { setToken } = useContext(AppContext);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [toast, setToast] = useState({
    message: '',
    type: 'error',
    show: false
  });

  const handleLogin = async (values) => {
    const modifiedVal = { ...values, usernameOrEmail: values.email };
    delete modifiedVal.email;
    const result = await signInAPI(modifiedVal);

    if (result.success === false) {
      const errorMessage = 'Неправильные данные авторизации!';
      setToast({ message: errorMessage, type: 'error', show: true });
      return;
    }

    dispatch(updateToken({ token: result.accessToken }));
    dispatch(updateUser({ info: result.info }));
    localStorage.setItem('userToken', result.accessToken);
    if (localStorage.getItem('passedInstruction')) {
      navigate('/');
    } else {
      navigate('/info');
    }
  };

  useEffect(() => {
    if (location.state?.show && location.navigationType !== 'push') {
      setToast(location.state);
    }
  }, [location.state]);

  return (
    <div className="loginFormContainer">
      {toast.show && <Toaster toast={toast} setToast={setToast} />}

      <div className="loginCard">
        <h1 className="cardTitle">Vinza</h1>
        <div className="heroPic" />
        <Form layout="vertical" onFinish={handleLogin}>
          <div className="signInTitle">Sign In</div>
          <div className="signInMessage">
            Don&apos;t have an account{' '}
            <Link to="/signUp" className="signUpLink">
              Sign up
            </Link>
          </div>

          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your Email!' },
              { type: 'email', message: 'Invalid email address' },
              {
                max: 40,
                message: 'Email cannot be longer than 40 characters'
              }
            ]}>
            <Input prefix={<div className="emailIcon" />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Please input your password!' },
              {
                min: 4,
                message: 'Password must be at least 4 characters long'
              },
              {
                max: 26,
                message: 'Value should be less than 26 character'
              }
            ]}>
            <Input.Password prefix={<div className="lockIcon" />} placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="loginButton">
              Sign In
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default SignIn;
