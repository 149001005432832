import React, { useEffect, useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import {} from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Spin } from 'antd';
import { plansData, dissapearNumbers } from '../constants';
import './index.scss';
const Curtain = (props) => {
  const { fontSize } = props;
  const { userData } = useSelector((state) => state.user);
  const [progressVal, setProgressVal] = useState(0);
  const [barNums, setBarNums] = useState(0);
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/history');
  };

  const getProgressText = (idx) => {
    if (userData.diamondtype === 4) {
      return 'speed';
    } else if (userData.diamondtype === 5) {
      return 'value';
    } else if (userData.diamondtype === 6) {
      return idx === 0 ? 'speed' : 'value';
    }
  };

  const getProgressVal = (diamondTime) => {
    const curDate = new Date();
    let d1 = new Date(diamondTime);

    let d2 = new Date(
      curDate.getUTCFullYear(),
      curDate.getUTCMonth(),
      curDate.getUTCDate(),
      curDate.getUTCHours(),
      curDate.getUTCMinutes(),
      curDate.getUTCSeconds()
    );

    const timeDifference = d1 - d2;
    const hoursDifference = timeDifference / (1000 * 60 * 60);

    const progressPercentage = hoursDifference * 4.17;
    const cappedProgress = Math.min(100, Math.max(0, progressPercentage));
    return cappedProgress;
  };

  useEffect(() => {
    if (userData.diamondtime) {
      setProgressVal(getProgressVal(userData.diamondtime));
      if (userData.diamondtype !== 0) {
        setBarNums(userData.diamondtype <= 5 ? 1 : 2);
      }
    }
  }, [userData.diamondtime]);

  return (
    <div className="curtain" onClick={handleRedirect}>
      <div className="curtainPlan">
        <img
          src={plansData[userData.plans]?.icon || 'plans/rookieIcon.png'}
          width={32}
          height={32}
        />
        <p className={plansData[userData.plans]?.color}>{plansData[userData.plans]?.title}</p>
      </div>
      <div className="userBalanceBlock">
        <p className="dollarSign">$</p>

        <h1 style={{ fontSize: fontSize + 'px' }} className="userBalanceNum">
          {userData?.balance ?? <Spin />}
        </h1>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', gap: 3 }}>
        {[...Array(barNums)].map((_, idx) => {
          return (
            <div key={idx} className="circularProgressContainer">
              <div style={{ width: '32px', height: '32px' }} className="circularProgress">
                <CircularProgressbarWithChildren
                  value={progressVal}
                  styles={buildStyles({
                    strokeLinecap: 'rounded',
                    strokeWidth: 20,
                    strokeSize: 80,
                    textSize: '16px',
                    pathColor: '  #EDC1EC',
                    textColor: '#f88',
                    trailColor: ' #AB74A1',
                    backgroundColor: '#3e98c7'
                  })}>
                  <svg
                    className="circularProgressSvg"
                    width="16"
                    height="16"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g id="tabler-icon-diamond">
                      <path
                        id="Vector"
                        d="M13.3333 15.9997L10.6667 13.0664L11.4667 11.733M8 6.66663H24L28 13.3333L16.6667 26C16.5798 26.0887 16.476 26.1591 16.3616 26.2072C16.2471 26.2553 16.1242 26.2801 16 26.2801C15.8758 26.2801 15.7529 26.2553 15.6384 26.2072C15.524 26.1591 15.4202 26.0887 15.3333 26L4 13.3333L8 6.66663Z"
                        stroke="#699BB6"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                </CircularProgressbarWithChildren>
              </div>
              <p className="circularProgressText">+20% deal {getProgressText(idx)}</p>
            </div>
          );
        })}
      </div>

      <div className="diagonalBlink" />
      <div className="dissapearNumbers">
        {dissapearNumbers.map(({ style }) => (
          <div className={style} key={style}>
            $
          </div>
        ))}
      </div>
    </div>
  );
};

export default Curtain;

Curtain.propTypes = {
  fontSize: PropTypes.number
};
