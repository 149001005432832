// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Bitter:wght@400;700;900&family=Crimson+Pro:wght@700;900&family=Noto+Serif:wght@900&family=Roboto:wght@400;500;700;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.continueButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  animation: continue-appearance 1.5s ease-in-out;
  background: linear-gradient(to right, #6D4ECB, #FF6B6B);
  font-weight: 400;
  margin-top: 50px;
  margin-right: 40px;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  border: 0px solid;
  bottom: 30px;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/common/gradientButton/index.scss","webpack://./src/scss/colors.scss","webpack://./src/scss/fonts.scss"],"names":[],"mappings":"AAIA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,+CAAA;EACA,uDAAA;EACA,gBAAA;EACA,gBAAA;EAEA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,cClBS;EDmBT,iCEdK;EFeL,eAAA;EACA,iBAAA;EACA,YAAA;EACA,cAAA;AAHJ","sourcesContent":["@import '../../scss/colors.scss';\n@import '../../scss/fonts.scss';\n\n\n.continueButton{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 320px;\n    animation: continue-appearance 1.5s ease-in-out;\n    background: linear-gradient(to right, $color-Gradient-3-1, $color-Gradient-2-2);\n    font-weight: 400;\n    margin-top: 50px;\n    \n    margin-right: 40px;\n    padding: 15px;\n    border-radius: 10px;\n    text-align: center;\n    color: $color-White;\n    font-family: $Roboto;\n    font-size: 16px;\n    border:0px solid;\n    bottom: 30px;\n    margin: 0 auto;\n  }","$color-White:#FFFFFF;\n$color-Secondary:#699BB6;\n\n$color-Gradient-1-2:#002F48;\n$color-Gradient-2-2:#FF6B6B;\n$color-Gradient-3-2:#987EE4;\n$color-Gradient-4-2:#F595DF; \n$color-Gradient-5-2:#FEA5A5;\n\n$color-Gradient-1-1:#216B93;\n$color-Gradient-2-1:#F595DF;\n$color-Gradient-3-1:#6D4ECB;\n$color-Gradient-4-1:#C465AE;\n\n","@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Bitter:wght@400;700;900&family=Crimson+Pro:wght@700;900&family=Noto+Serif:wght@900&family=Roboto:wght@400;500;700;900&display=swap');\n$Bitter: 'Bitter', serif;   \n$Crimson-Pro: 'Crimson Pro', serif;\n$Alfa-Slab-One: 'Alfa Slab One', serif;\n$Noto-Serif: 'Noto Serif', serif;\n$Roboto: 'Roboto', sans-serif;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
