import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import '../index.scss';
import ellipseImg from '../../../assets/img/notiEllipse.jpeg';
import readNotificationsAPI from '../../../api/user/readNotifications';
const CustomNotification = () => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.user);

  const location = useLocation();

  const handleNotification = async (action) => {
    await readNotificationsAPI(token, navigate, {
      body: { messageIds: [location.state.id], action, markAll: false }
    });
    navigate('/');
  };
  return (
    <div style={{ backgroundColor: '#fff' }} className="notificationMain">
      <div className="customNotifcationContainer">
        <img className="ellipse" src={ellipseImg} width={257} height={257} />
        <h2 className="headerText">Offer!</h2>
        <p className="description">{location.state.message} </p>

        <button
          className="successBtn"
          onClick={() => {
            handleNotification(1);
          }}>
          Activate now
        </button>
        <button
          className="skipBtn"
          onClick={() => {
            handleNotification(0);
          }}>
          Remind me later
        </button>
      </div>
    </div>
  );
};

export default CustomNotification;
