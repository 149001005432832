import React, { useEffect, useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import './index.scss';
import { getTradesThunk } from '../../store/slices/diamonds';
import Toaster from '../../../common/toaster';
import TradeCard from './tradeCard';
import { diamondsConstants } from '../constants';
const Trades = () => {
  const { token } = useSelector((state) => state.user);
  const { trades } = useSelector((state) => state.diamonds);

  const [toast, setToast] = useState({
    message: '',
    type: 'error',
    show: false
  });
  const [tradesList, setTradesList] = useState([]);
  const navigate = useNavigate('');
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (token && location.pathname === '/diamonds/trades') {
      dispatch(getTradesThunk({ token, navigate, setToast }));
    }
  }, [token, location.pathname]);

  useEffect(() => {
    if (trades.length) {
      let data = trades.map((tradeData) => {
        return {
          ...tradeData,
          icon: diamondsConstants[tradeData.type].icon,
          title: diamondsConstants[tradeData.type].title,
          description: diamondsConstants[tradeData.type].description
        };
      });
      setTradesList(data);
    }
  }, [trades]);

  return (
    <div className="tradesContainer">
      {toast.show && <Toaster toast={toast} setToast={setToast} />}

      <div className="header">
        <h1>Treasure Market</h1>
      </div>
      <div className="tradeCards">
        {!tradesList.length && <div className="noDiamonds">No Diamonds For Sale</div>}

        {tradesList.map((trade) => {
          return <TradeCard key={trade.id} trade={trade} />;
        })}
      </div>
    </div>
  );
};
export default Trades;
