import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
const GradientButton = ({ handleClickButton, text, absolute }) => {
  return (
    <button
      style={absolute ? { position: 'absolute' } : { marginTop: '20px' }}
      className="continueButton"
      onClick={(e) => {
        e.stopPropagation();
        handleClickButton();
      }}>
      {text || 'Continue'}
    </button>
  );
};

GradientButton.propTypes = {
  absolute: PropTypes.bool,
  handleClickButton: PropTypes.func.isRequired,
  text: PropTypes.string
};

export default GradientButton;
