import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Spin } from 'antd';
import { useSelector } from 'react-redux';

import './index.scss';

import { AppContext } from '../appContext';
import getInfoAPI from '../../api/history/getInfo';
import HistoryCard from './historyCard';
import TopActions from '../../common/topActions';
const History = () => {
  const { setLoadingHistory } = useContext(AppContext);

  const { token } = useSelector((state) => state.user);

  const [historyData, setHistoryData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [userReachedEnd, setUserReachedEnd] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const userData = useSelector((state) => state.user?.userData);
  const historyContentRef = useRef(null);

  const navigate = useNavigate();
  const parseHistoryData = async (pageNum = 1) => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    const dateEnd = `${year}-${month}-${day}`;
    setIsLoading(true);

    const result = await getInfoAPI(token, navigate, setLoadingHistory, dateEnd, pageNum);
    setIsLoading(false);

    if (historyData?.data) {
      setHistoryData({
        ...historyData,
        data: {
          ...historyData?.data,
          history: [...historyData.data.history, ...result.data.history]
        }
      });
    } else {
      setHistoryData(result);
    }
  };

  const renderFooter = () => {
    if (isLoading) {
      return (
        <div>
          <Spin />
        </div>
      );
    } else if (!isLoading && userReachedEnd) {
      return <p className="endListText">You&apos;ve reached the end</p>;
    }
  };
  const handleWidthdraw = () => {
    if (userData?.profit < 10) {
      setIsModalOpen(!isModalOpen);
      return false;
    }
    navigate('/withdraw');
  };
  const renderHistory = () => {
    if (historyData.data?.history.length) {
      return (
        <>
          {historyData.data?.history.map((historyEl) => {
            return <HistoryCard key={historyEl.id + currentPage} data={historyEl} />;
          })}
          <div className="historyFooter">{renderFooter()}</div>
        </>
      );
    } else {
      return <p className="noHistoryText">There is no history yet</p>;
    }
  };

  useEffect(() => {
    if (token) {
      try {
        parseHistoryData(currentPage);
      } catch (e) {
        // console.error('There occured to be error on get history data', e);
      }
    }
  }, [token]);

  useEffect(() => {
    const historyContent = historyContentRef.current;
    if (historyContent) {
      const isAtBottom = () => {
        return (
          historyContent.scrollTop + historyContent.clientHeight >= historyContent.scrollHeight - 5
        );
      };
      const handleScroll = () => {
        const newPage = currentPage + 1;
        if (isAtBottom() && !isLoading && newPage <= historyData.data.total) {
          setCurrentPage(newPage);
          parseHistoryData(newPage);
        } else if (newPage > historyData.data.total) {
          setUserReachedEnd(true);
        }
      };

      historyContent.addEventListener('scroll', handleScroll);
      historyContent.addEventListener('touchmove', handleScroll);
      return () => {
        historyContent.removeEventListener('scroll', handleScroll);
        historyContent.removeEventListener('touchmove', handleScroll);
      };
    }
  }, [isLoading, historyContentRef]);

  return (
    <div className="historyMain">
      {!userData.email ? (
        <Spin />
      ) : (
        <div className=" historyContainer">
          <TopActions tradingLog left center="Trading Log" />

          <div className="historyHeader">
            <div className="profitBlock">
              <h2>TOTAL PROFIT</h2>
              <p>
                <span className="dollarSign">$</span> {userData.profit}
              </p>
            </div>
            <div className="balanceRow">
              <div className="balanceBlock">
                <h2>CURRENT BALANCE</h2>
                <p>
                  <span className="dollarSign">$</span> {userData.balance}
                </p>
              </div>
              <div className="buttonsBlock">
                <button onClick={() => handleWidthdraw()} className="withdrawBtn">
                  Withdrawal
                </button>
                <Modal
                  title="Withdraw"
                  open={isModalOpen}
                  onOk={() => setIsModalOpen(false)}
                  centered
                  styles={{
                    body: {
                      backgroundColor: '#07405E',
                      color: 'white'
                    },
                    header: {
                      backgroundColor: '#07405E',
                      color: 'white'
                    },
                    footer: {
                      backgroundColor: '#07405E',
                      color: 'white'
                    }
                  }}
                  onCancel={() => setIsModalOpen(false)}
                  classNames="withdrawModal">
                  <p>
                    To proceed with the translation, a minimum profit of $10 is required. Please
                    ensure that your transaction generates this amount of profit before continuing
                  </p>
                </Modal>
              </div>
            </div>
          </div>
          <div ref={historyContentRef} className="historyContent">
            {renderHistory()}
          </div>
        </div>
      )}
    </div>
  );
};
export default History;
