import ApiUtils from '../apiUtils';

const createChestAPI = async (token, navigate) => {
  const url = `${ApiUtils.API_BASE_URL}/key/create/box`;
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      'X-Custom-Header': 'getDiamonds',
      Authorization: `Bearer ${token}`
    }
  };
  return await fetch(url, options)
    .then(async (response) => {
      const parsed = await ApiUtils.checkStatus(response, navigate);
      return parsed;
    })
    .then((parsed) => {
      return parsed.json();
    })
    .catch((err) => err);
};
export default createChestAPI;
