import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

import { getSizeOfDiamond } from '../../../../../../helpers/getSizeOfDiamond';
import { diamondsConstants } from '../../../../constants';
const SelectCard = ({ selected, selectedDiamond, handleClickDiamond }) => {
  return (
    <div
      className="selectedDiamondCard"
      onClick={() => handleClickDiamond(selectedDiamond)}
      style={selected ? { maxWidth: '85px' } : { minWidth: 140, maxWidth: 160 }}>
      {selected && (
        <div className="deselectItem">
          <div className="close" />
        </div>
      )}
      <div
        style={{
          backgroundSize: getSizeOfDiamond(selectedDiamond.type),
          backgroundImage: `url(${diamondsConstants[selectedDiamond.type]?.icon})`
        }}
        className="selectedDiamondImg"
      />
      {!selected && (
        <div className="cardHeading">
          <p className="title"> {diamondsConstants[selectedDiamond.type].title}</p>
        </div>
      )}
    </div>
  );
};

SelectCard.propTypes = {
  selected: PropTypes.bool,
  selectedDiamond: PropTypes.object,
  handleClickDiamond: PropTypes.func
};

export default SelectCard;
