// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.diamondUsage {
  position: fixed;
  animation: appearance linear 1.5s;
  opacity: 0.7;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  z-index: 6;
}

@keyframes appearance {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.8;
  }
}`, "",{"version":3,"sources":["webpack://./src/modules/diamonds/diamondsList/diamondScreen/activation/index.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EAEA,iCAAA;EACA,YAAA;EAEA,mCAAA;UAAA,2BAAA;EACA,UAAA;AADJ;;AAIA;EACI;IACE,UAAA;EADJ;EAGE;IACE,YAAA;EADJ;EAGE;IACE,YAAA;EADJ;AACF","sourcesContent":[".diamondUsage{\n    position: fixed;\n    // background-color: rgba($color: #000000, $alpha: 0.3);\n    animation: appearance linear 1.5s ;\n    opacity: 0.7;\n\n    backdrop-filter: blur(10px);\n    z-index: 6;\n}\n\n@keyframes appearance {\n    0% {\n      opacity: 0;\n    }\n    50% {\n      opacity: 0.5;\n    }\n    100% {\n      opacity: 0.8;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
