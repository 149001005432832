const ApiUtils = {
  checkStatus: async (response, navigate) => {
    if (response.status !== 200 && response.status !== 201) {
      if (response.status === 401 && navigate) {
        window.localStorage.removeItem('userToken');
        navigate('/signIn');
      }
      throw response.status;
    } else {
      return response;
    }
  },
  API_BASE_URL: 'https://api.vinzagame.com/api'
};

export { ApiUtils as default };
