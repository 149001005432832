import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { AppContext } from '../appContext';
import './index.scss';
import TopActions from '../../common/topActions';
import { Switch } from 'antd';
import editUserAPI from '../../api/user/editUser';
import getUserAPI from '../../api/user/getUser';
import addTrcAPI from '../../api/user/addTrc';
import Toaster from '../../common/toaster';
import addTgAPI from '../../api/user/addTg';
import { addHissKeyAPI } from '../../api/user/addHiss';
import { updateToken, updateUser } from '../store/slices/user';
import chevronIcon from '../../assets/icons/chevronIcon.svg';
const Settings = () => {
  const { setLoading } = useContext(AppContext);
  const { token } = useSelector((state) => state.user);

  const userData = useSelector((state) => state.user?.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [telegramName, setTelegramName] = useState('');
  const [changePassword, setChangePassword] = useState(false);
  const [cryptoWallet, setCryptoWallet] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [hissKey, setHissKey] = useState('');
  const [toast, setToast] = useState({
    message: '',
    type: 'error',
    show: false
  });

  const handleLogout = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('userToken');
    // setToken('');
    // setUserData('');
    dispatch(updateToken({ token: '' }));
    dispatch(updateUser({ info: '' }));
  };

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const validateUsername = (username) => {
    return username.trim() !== '' && username.trim().length <= 40;
  };

  const checkIfChangedInputs = () => {
    if (checkIfChangedUserData()) {
      return true;
    }
    if (telegramName !== userData.tg) {
      return true;
    }
    if (cryptoWallet !== userData.trc) {
      return true;
    }
    if (hissKey !== userData.hisskey) {
      return true;
    }
    return false;
  };
  const checkIfChangedUserData = () => {
    if (name !== userData.name) {
      return true;
    }
    if (email !== userData.email) {
      return true;
    }
    if (password.length) {
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    if (!checkIfChangedInputs()) {
      return true;
    }
    if (!validateEmail(email)) {
      setEmailError('Invalid email address');
      return;
    }

    if (!validateUsername(name)) {
      setUsernameError('Invalid name');
      return;
    }
    setUsernameError('');
    setEmailError('');
    let dataPass = password;
    if (password === '') {
      dataPass = null;
    }
    const data = {
      email: email,
      name: name,
      password: dataPass,
      username: userData?.username || ''
    };
    let respEditUser = {};
    if (checkIfChangedUserData()) {
      respEditUser = await editUserAPI(token, navigate, data);
    }

    let respTrc = { success: true };
    let respTg = { success: true };
    let respHiss = { success: true };

    if (cryptoWallet !== userData?.trc) {
      respTrc = await addTrcAPI(token, navigate, { trc: cryptoWallet });
    }
    if (telegramName !== userData?.tg) {
      respTg = await addTgAPI(token, navigate, { tg: telegramName });
    }
    if (hissKey !== userData?.hisskey) {
      respHiss = await addHissKeyAPI(token, navigate, { hissKey });
    }

    if (respTrc?.success || respEditUser?.success || respTg?.success) {
      const message = 'Data updated successfully!';
      setToast({ message: message, type: 'success', show: true });
    }
    if (!respTrc?.success) {
      setToast({ message: 'Please enter valid crypto wallet', type: 'error', show: true });
    }
    if (respEditUser === 400) {
      setToast({ message: 'Please enter valid user data', type: 'error', show: true });
    }
    if (!respTg?.success) {
      setToast({ message: 'Please enter valid telegram username', type: 'error', show: true });
    }
    if (!respHiss?.success) {
      setToast({ message: 'Hiss key is invalid', type: 'error', show: true });
    }

    const result = await getUserAPI(token, navigate, setLoading);

    if (result?.message && result?.message.indexOf('Failed') === -1) {
      dispatch(updateUser({ info: JSON.parse(result?.message).info }));
    }
  };

  useEffect(() => {
    if (userData) {
      setName(userData.name);
      setEmail(userData.email);
      setTelegramName(userData.tg);
      setCryptoWallet(userData.trc);
      setHissKey(userData.hisskey);
    }
  }, [userData]);
  return (
    <div className="settingsMain">
      {toast.show && <Toaster toast={toast} setToast={setToast} />}

      <div className="settingsContainer">
        {' '}
        <TopActions left center="Settings" right={handleLogout} rightText="Logout" />
        <div className="inputContainer">
          <h2 className="generalLabel">Information</h2>
          <a onClick={() => navigate('/gameGoal')} className="inputBlock informationInput">
            Game Goal
            <img src={chevronIcon} />
          </a>
          <h2 className="generalLabel">GENERAL</h2>
          <div className="inputBlock nameInput">
            <label htmlFor="name">Name</label>
            <input
              value={name || ''}
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="settingsInput"
              type="text"
              id="name"
            />
            {usernameError && (
              <span className={`settingsError ${usernameError ? 'fade-in' : ''}`}>
                {usernameError}
              </span>
            )}
          </div>
          <div className="inputBlock">
            <label htmlFor="name">Email</label>
            <input
              value={email || ''}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className="settingsInput"
              type="email"
              id="email"
            />
            {emailError && (
              <span className={`settingsError ${emailError ? 'fade-in' : ''}`}>{emailError}</span>
            )}
          </div>
          <div className={`inputBlock switchBlock ${!changePassword && 'rounded'}`}>
            <label htmlFor="name">Change Password</label>
            <Switch
              className="custom-switch"
              checked={changePassword}
              onChange={() => {
                setChangePassword(!changePassword);
              }}
            />
          </div>
          {changePassword && (
            <div className="inputBlock passwordInput">
              <label htmlFor="name">Password</label>
              <input
                className="settingsInput"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                type="password"
                id="password"
              />
            </div>
          )}
          <h2 className="generalLabel">NOTIFICATION</h2>
          <div className="inputBlock telegramInput">
            <label htmlFor="name">Telegram</label>
            <input
              className="settingsInput"
              value={telegramName || ''}
              onChange={(e) => {
                setTelegramName(e.target.value);
              }}
              type="text"
              id="notification"
            />
          </div>
          <h2 className="generalLabel">CRYPTO WALLET</h2>
          <div className="inputBlock  walletInput">
            <label htmlFor="name">TRC20-USDT</label>
            <input
              className="settingsInput"
              value={cryptoWallet || ''}
              onChange={(e) => {
                setCryptoWallet(e.target.value);
              }}
              type="text"
              id="telegramName"
            />
            <span className="walletDescription">
              TRC20-USDT wallet address for transfer earned funds.
            </span>
          </div>
          <div className="inputBlock  hissInput">
            <label htmlFor="name">HISS KEY</label>
            <input
              className="settingsInput"
              value={hissKey || ''}
              onChange={(e) => {
                setHissKey(e.target.value);
              }}
              type="text"
              id="hissKey"
            />
          </div>
          <button className="saveBtn" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
export default Settings;
