import React from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import '../index.scss';
import './index.scss';

import { diamondsConstants } from '../constants';
import DiamondCard from './diamondCard';

const DiamondsList = () => {
  const { sortedDiamonds, loading } = useSelector((state) => state.diamonds);
  if (loading || !Object.keys(sortedDiamonds).length) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spin />
      </div>
    );
  }

  return (
    <div>
      <div className="diamondsContainer">
        {diamondsConstants.map((diamond, idx) => {
          return (
            <DiamondCard
              key={diamond.type}
              diamond={diamond}
              filteredDiamonds={sortedDiamonds}
              idx={idx}
            />
          );
        })}
      </div>
    </div>
  );
};
export default DiamondsList;
