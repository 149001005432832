import { combineReducers } from 'redux';
import user from './slices/user';
import notifications from './slices/notifications';
import diamonds from './slices/diamonds';

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    diamonds,
    notifications,
    user,
    ...asyncReducers
  });
  return combinedReducer(state, action);
};

export default rootReducer;
