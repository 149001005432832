import ApiUtils from '../../apiUtils';

const listForExchangeAPI = async (token, navigate, exchangeList) => {
  const url = `${ApiUtils.API_BASE_URL}/exchange/list`;
  const options = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      'X-Custom-Header': 'getDiamonds',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(exchangeList)
  };
  return await fetch(url, options)
    .then(async (response) => {
      const parsed = await ApiUtils.checkStatus(response, navigate);
      return parsed;
    })
    .then((parsed) => {
      return parsed.json();
    })
    .catch((err) => err);
};
export default listForExchangeAPI;
